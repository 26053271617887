import { apis } from "apis";
import ServiceBase from "./service-base";
import IHttpResponse from "src/http/http-response";
import HttpClient from "src/http/http-client";

/**
 * 医疗服务订单服务。
 * @class
 * @version 1.0.0
 */
export default class MedicalOrserService extends ServiceBase {
  /*
   * 医疗服务实例
   * @private
   * @return MedicalOrserService
   */
  private static _instance: MedicalOrserService;

  /**
   * 医疗订单服务实例
   * @static
   * @property
   * @returns MedicalOrserService
   */
  public static get instance(): MedicalOrserService {
    if (!this._instance) {
      this._instance = new MedicalOrserService();
    }

    return this._instance;
  }

  /**
   * 客户端订单预信息（下单前的信息汇总
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async getPreMedicalOrderInfo(id: string | number): Promise<IHttpResponse> {
    let response = await this.apis.getPreMedicalOrderInfo({
      data: { id },
    });

    return response;
  }

  /**
   * 客户端下订单
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async createMedicalOrder(data: any): Promise<IHttpResponse> {
    let response = await this.apis.createMedicalOrder({
      data,
    });

    return response;
  }

  /**
   * 获取服务订单详情
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async getMedicalOrderDetail(orderId: any, loading: boolean = true): Promise<IHttpResponse> {
    let response = await this.apis.getMedicalOrderDetail({
      data: {
        id: orderId,
      },
      loading: false,
    });

    return response;
  }

  /**
   * 获取订单支持的机构
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async getMedicalOrderSupportHospitals(data: any): Promise<IHttpResponse> {
    let response = await this.apis.getMedicalOrderSupportHospitals({
      data: data,
    });

    return response;
  }

  /**
   * 客户端订单预约
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async appointMedicalOrder(data: any): Promise<IHttpResponse> {
    let response = await this.apis.appointMedicalOrder({
      data: data,
    });

    return response;
  }

  /**
   * 获取机构排期
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async getMedicalOrderSchedule(id: string | number): Promise<IHttpResponse> {
    let response = await this.apis.getMedicalOrderSchedule({
      params: {
        id: id,
      },
    });

    return response;
  }

  /**
   * 改约
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async changeMedicalOrderSchedule(data: any): Promise<IHttpResponse> {
    let response = await this.apis.changeMedicalOrderSchedule({
      data: data,
    });

    return response;
  }

  /**
   * 客户订单退款
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async refundMedicalOrder(id: any): Promise<IHttpResponse> {
    let response = await this.apis.refundMedicalOrder({
      params: { id },
    });

    return response;
  }

  /**
   * 取消服务订单
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async cancelMedicalOrder(id: any): Promise<IHttpResponse> {
    let response = await this.apis.cancelMedicalOrder({
      params: {
        id,
      },
    });

    return response;
  }

  /**
   * 取消预约服务
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async cancelAppointMedicalOrder(id: any): Promise<IHttpResponse> {
    let response = await this.apis.cancelAppointMedicalOrder({
      params: {
        id,
      },
    });

    return response;
  }

  /**
   * 继续支付
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async proceedPayMedicalOrder(id: any): Promise<IHttpResponse> {
    let response = await this.apis.proceedPayMedicalOrder({
      data: {
        id,
      },
    });

    return response;
  }

  /**
   * 绿通订单提交预约信息
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async submitOrderProductForm(data: any): Promise<IHttpResponse> {
    let response = await this.apis.submitOrderProductForm({
      data,
    });

    return response;
  }

  /**
   * 绿通订单获取预约信息
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async getOrderProductForm(id: any): Promise<IHttpResponse> {
    let response = await this.apis.getOrderProductForm({
      data: {
        id,
      },
    });

    return response;
  }

  /**
   * 绿通订单确认订单
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async confirmOrder(data: any): Promise<IHttpResponse> {
    let response = await this.apis.confirmOrder({
      data,
    });

    return response;
  }
}
