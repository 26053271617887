import { apis } from "apis";
import ServiceBase from "./service-base";
import IHttpResponse from "src/http/http-response";

/**
 * 权益服务。
 * @class
 * @version 1.0.0
 */
export default class RightsService extends ServiceBase {
  /*
   * 权益服务实例
   * @private
   * @return GaodeService
   */
  private static _instance: RightsService;

  /**
   * 权益服务实例
   * @static
   * @property
   * @returns RightsService
   */
  public static get instance(): RightsService {
    if (!this._instance) {
      this._instance = new RightsService();
    }

    return this._instance;
  }

  /**
   * 客户端获取订单列表数据
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async clientList(data: any): Promise<IHttpResponse> {
    let response = await this.apis.clientList({
      data: data,
    });

    return response;
  }

  /**
   * 客户端获取订单详情
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async getClient(id: any): Promise<IHttpResponse> {
    let response = await this.apis.getClient({
      params: {
        id,
      },
    });

    return response;
  }

  /**
   * 客户端取消订单
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async clientCancel(id: any): Promise<IHttpResponse> {
    let response = await this.apis.clientCancel({
      params: {
        id,
      },
    });

    return response;
  }

  /**
   * 客户端激活订单
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async clientActive(id: any): Promise<IHttpResponse> {
    let response = await this.apis.clientActive({
      params: {
        id,
      },
    });

    return response;
  }

  /**
   * 客户端获取卡片信息
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async clientGetCard(id: any): Promise<IHttpResponse> {
    let response = await this.apis.clientGetCard({
      params: {
        id,
      },
    });

    return response;
  }

  /**
   * 通过人员权益id获取卡片信息
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async clientGetCardByMemberRightId(id: any): Promise<IHttpResponse> {
    let response = await this.apis.clientGetCardByMemberRightId({
      params: {
        id,
      },
    });

    return response;
  }

  /**
   * 客户端权益详情查询
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async getEmployerRights(id: any): Promise<IHttpResponse> {
    let response = await this.apis.getEmployerRights({
      params: {
        id,
      },
    });

    return response;
  }
}
