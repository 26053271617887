
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

/**
 * 返回组件
 * @class
 * @version 1.0.0
 */
@Component
export default class PageBack extends Vue {
  /**
   * 显示返回主页
   * @private
   * @returns boolean
   */
  @Prop({ type: Boolean, default: true })
  private showHome: boolean;

  /**
   * 标题显示
   * @private
   * @returns string
   */
  @Prop({ type: String, required: true })
  private title: string;

  /**
   * 名称
   * @private
   * @returns string
   */
  @Prop({ type: String })
  private pathName: string;

  /**
   * 自定义方法
   * @private
   * @returns string
   */
  @Prop({ type: Function })
  private customFn: any;

  /**
   * 返回
   * @private
   * @returns void
   */
  private goBack(): void {
    if (this.isAi) {
      this.$router.go(-1);
      return;
    }
    if (this.customFn) {
      this.customFn();
    } else if (this.pathName) {
      this.$router.push({ name: this.pathName });
    } else {
      this.$router.go(-1);
    }
  }

  /**
   * 跳转首页
   * @private
   * @returns void
   */
  private goHome(): void {
    this.$router.replace({ name: "home" });
  }

  /**
   * 是否是ai跳转过来
   * @private
   * @returns boolean
   */
  private get isAi(): boolean {
    return this.$route.query && this.$route.query.isAi == "true" ? true : false;
  }
}
