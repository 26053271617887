
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { LocationcityService, GaodeService } from "src/services";
import { Toast } from "vant";
import globalConfig from "../../config/index";

@Component
export default class History extends Vue {
  /**
   * 是否显示
   * @private
   * @returns string
   */
  @Prop({ type: Boolean, default: false })
  private value: boolean;

  /**
   * 热门城市
   * @private
   * return Array<string>
   */
  private hotCity: Array<string> = ["北京市", "上海市", "广州市", "深圳市", "南京市", "杭州市", "成都市", "武汉市"];

  /**
   * 索引字符列表
   * @private
   * @returns Array<string>
   */
  private indexList: Array<string> = [
    "当前",
    "历史",
    "热门",
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "J",
    "K",
    "L",
    "M",
    "N",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "W",
    "X",
    "Y",
    "Z",
  ];

  /**
   * 搜索关键字
   * @private
   * @returns string
   */
  private searchKey: string = "";

  /**
   * 当前位置
   * @private
   * @returns string
   */
  private currentLocaltion: string = "";

  /**
   * 城市列表
   * @private
   * @returns Array<string>
   */
  private cityList: Array<string> = [];

  /**
   * 搜索结果
   * @private
   * @returns Array<string>
   */
  private searchResult: Array<any> = [];

  /**
   * 搜索结果的弹框显示或隐藏
   * @private
   * @returns
   */
  private showSeachModel: boolean = false;

  /**
   * 历史数据集合
   * @private
   * @returns Array<string>
   */
  private get historyCityList(): Array<string> {
    return this.$store.getters.historyCityList || [];
  }

  /**
   * 是否显示日期弹框
   * @private
   * @returns boolean
   */
  private get showModel(): boolean {
    return this.value;
  }

  /**
   * 返回弹框状态
   * @private
   */
  private set showModel(value: boolean) {
    this.$emit("input", value);
  }

  /**
   * 触发搜索城市事件
   * @private
   * @returns string
   */
  private onSearchCity(): void {
    this.onSearch();
  }

  /**
   * 缓存中选中城市
   * @private
   * @returns any
   */
  private get cacheCurrentLocaltion(): any {
    return this.$store.getters.currentLocaltion;
  }

  /**
   * 获取当前位置信息
   * @private
   * @returns void
   */
  private async getGeolocation(): Promise<void> {
    let appid = localStorage.getItem("appid");
    let employerConfig = globalConfig.employerConfig[appid];
    if (employerConfig && employerConfig.unLocation) {
      return;
    }
    let data = await GaodeService.instance.getGeolocation();
    this.currentLocaltion = data.city;
  }

  /**
   * 取消搜索城市事件
   * @private
   * @returns void
   */
  private onClearCityRseult(): void {
    this.searchResult = this.cityList;
  }

  /**
   * 商品订单id
   * @private
   * @returns string
   */
  private get id(): string {
    return (this.$route.query && (this.$route.query.productId as string)) || "";
  }

  /**
   * 选中城市
   * @private
   * @returns void
   */
  private onSelectCity(city: string): void {
    let historyCityList = this.historyCityList || [];
    let selectIndex = historyCityList.indexOf(city);

    if (historyCityList && historyCityList.length < 3 && selectIndex === -1) {
      historyCityList.push(city);
    } else if (selectIndex > -1) {
      historyCityList.splice(selectIndex, 1);
      historyCityList.push(city);
    } else {
      historyCityList.shift();
      historyCityList.push(city);
    }
    this.showModel = false;

    this.$store.dispatch("setHistoryCityList", historyCityList);

    this.$emit("on-select-city", city);
    this.$store.dispatch("setCurrentLocation", city);
  }

  /**
   * 清空历史
   * @private
   * @returns void
   */
  private onClearHistory(city: string): void {
    this.$store.dispatch("setHistoryCityList", []);
  }

  /**
   * 搜索
   * @private
   * @returns void
   */
  private onSearch(): void {
    this.searchResult = this.cityList.map((item: any) => {
      const viewCityItems = item.ViewCityItems.filter((data) => data.CityName.indexOf(this.searchKey) > -1);
      if (viewCityItems && viewCityItems.length > 0) {
        return {
          ...item,
          ViewCityItems: viewCityItems,
        };
      }
    });
  }

  /**
   * 获取定位城市
   * @private
   * @returns Promise<void>
   */
  private async getLoctaionCity(): Promise<void> {
    try {
      let { content: result } = await LocationcityService.instance.locationCity(this.id);
      if (result.data) {
        this.cityList = result.data;
        this.searchResult = result.data;
      }
    } catch (err) {
      Toast(err);
    }
  }

  /**
   * 组件创建钩子
   * @private
   * @returns void
   */
  private created(): void {
    this.getLoctaionCity();
    if (this.cacheCurrentLocaltion) {
      this.currentLocaltion = this.cacheCurrentLocaltion;
    } else {
      this.getGeolocation();
    }
  }
}
