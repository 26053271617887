import { apis } from "apis";
import ServiceBase from "./service-base";
import IHttpResponse from "src/http/http-response";
import HttpClient from "src/http/http-client";

/**
 * 微信服务
 * @class
 * @version 1.0.0
 */
export default class WechatService extends ServiceBase {
  /*
   * 微信服务实例
   * @private
   * @return WechatService
   */
  private static _instance: WechatService;

  /**
   * 微信实例
   * @static
   * @property
   * @returns WechatService
   */
  public static get instance(): WechatService {
    if (!this._instance) {
      this._instance = new WechatService();
    }

    return this._instance;
  }

  /**
   * 获取微信jssdk
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async getWechatJSSDK(currentUrl: string): Promise<IHttpResponse> {
    let response = await this.apis.getWechatJSSDK({
      data: {
        url: currentUrl,
      },
    });

    return response;
  }

  /**
   * 获取微信当前定位
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async getWechatLocation(data: any): Promise<void> {
    return new Promise<any>((resolve, reject) => {
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: data.data.appId, // 必填，公众号的唯一标识
        timestamp: data.data.timestamp, // 必填，生成签名的时间戳
        nonceStr: data.data.nonceStr, // 必填，生成签名的随机串
        signature: data.data.signature, // 必填，签名
        jsApiList: ["getLocation"], // 必填，需要使用的JS接口列表
      });

      wx.ready(function () {
        wx.checkJsApi({
          jsApiList: ["getLocation"],
          success: function (res) {
            if ((res as any).checkResult.getLocation == false) {
              alert("你的微信版本太低，不支持微信JS接口，请升级到最新的微信版本！");
              return;
            }
          },
        });
        wx.error(function (res) {
          console.log("接口调取失败");
        });
        wx.getLocation({
          type: "gcj02",
          success: function (res) {
            console.log("地址接口调取成功！");
            resolve(res);
          },
          cancel: function (res) {
            let location = {
              longitude: 0,
              latitude: 0,
            };
            let orgInfoStorge = localStorage.getItem("orgInfo");
            let orgInfo = orgInfoStorge && JSON.parse(orgInfoStorge);

            location.latitude = (orgInfo && orgInfo.latitude) || 22.540269;
            location.longitude = (orgInfo && orgInfo.longitude) || 114.012325;
            resolve(location);
          },
          fail: function () {
            let location = {
              longitude: 0,
              latitude: 0,
            };
            let orgInfoStorge = localStorage.getItem("orgInfo");
            let orgInfo = orgInfoStorge && JSON.parse(orgInfoStorge);

            location.latitude = (orgInfo && orgInfo.latitude) || 22.540269;
            location.longitude = (orgInfo && orgInfo.longitude) || 114.012325;
            resolve(location);
          },
        });
      });
    });
  }

  /**
   * 微信分享
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async wechatShare(data: any, json: any): Promise<void> {
    // json
    // {
    //     title: '【xxxx旅游数字地图】', // 分享标题
    //     desc: '我在【xxxx旅游数字地图】获得了证书，快来一起参与吧', // 分享描述
    //     link: location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
    //     imgUrl: this.zslogo, // 分享图标
    //     success: function (res) {
    //         console.log(res)
    //         // 设置成功
    //     }
    // }
    return new Promise<any>((resolve, reject) => {
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: data.data.appId, // 必填，公众号的唯一标识
        timestamp: data.data.timestamp, // 必填，生成签名的时间戳
        nonceStr: data.data.nonceStr, // 必填，生成签名的随机串
        signature: data.data.signature, // 必填，签名
        jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"], // 必填，需要使用的JS接口列表
      });

      wx.ready(function () {
        wx.updateAppMessageShareData(json);
        wx.updateTimelineShareData(json);
      });
    });
  }
}
