const brokerLogin = () => import("src/views/broker/login.vue");
const employerList = () => import("src/views/broker/employer-list.vue");

export default [
  {
    path: "/broker/login",
    name: "brokerLogin",
    component: brokerLogin,
  },
  {
    path: "/employer/list",
    name: "employerList",
    component: employerList,
  },
];
