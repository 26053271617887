import { apis } from "apis";
import ServiceBase from "./service-base";
import IHttpResponse from "src/http/http-response";
import HttpClient from "src/http/http-client";
import { GaodeService, OrganizationService } from "src/services";

/**
 * 收货地址服务
 * @class
 * @version 1.0.0
 */
export default class BankService extends ServiceBase {
  /*
   * 地址服务实例
   * @private
   * @return BankService
   */
  private static _instance: BankService;

  /**
   * 地址服务实例
   * @static
   * @property
   * @returns BankService
   */
  public static get instance(): BankService {
    if (!this._instance) {
      this._instance = new BankService();
    }

    return this._instance;
  }

  /**
   * 根据卡号获取银行名称
   * @public
   * @param {any} data 需要操作的数据
   * @returns {Promise<IHttpResponse>}
   */
  public async getBankName(cardNo: any): Promise<IHttpResponse> {
    // 编辑
    let response = await this.apis.getBankName({
      params: { cardNo },
    });

    return response;
  }

  /**
   * 获取银行列表
   * @public
   * @param {any} data 需要操作的数据
   * @returns {Promise<IHttpResponse>}
   */
  public async getBankList(data): Promise<IHttpResponse> {
    // 编辑
    let response = await this.apis.getBankList({ data, loading: false });

    return response;
  }
}
