
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { WechatService, UserService } from "src/services";
import html2canvas from "html2canvas";
import QrcodeVue from "qrcode.vue";

/**
 * 分享组件
 * @class
 * @version 1.0.0
 */
@Component({
  components: {
    QrcodeVue,
  },
})
export default class Share extends Vue {
  /**
   * 数据源
   * @private
   * @returns string
   */
  @Prop({
    type: Object,
    default: {
      name: "",
      price: null,
      marketPrice: null,
      qrCode: "",
      bannerUrl: "",
      posterUrl: "",
    },
  })
  private dataSource: any;

  /**
   * 是否显示
   * @private
   * @returns string
   */
  @Prop({ type: Boolean, default: false })
  private showPopup: boolean;

  /**
   * 监听弹框变化
   * @private
   * @returns void
   */
  @Watch("showPopup", { immediate: true })
  private onShowPop(value: boolean): void {
    this.showSharePopup = value;
  }

  /**
   * 监听真实base64图片地址
   * @private
   * @returns void
   */
  @Watch("dataSource.name", { immediate: true, deep: true })
  private onChangeData(value: any): void {
    if (value) {
      this.onWxShareFn();
      if (!this.dataSource.bannerUrl) {
        if (this.orgInfo.logo) {
          this.getUrl2base64(this.orgInfo.logo, "orgLogo");
        } else {
          setTimeout(() => {
            this.onSaveImg();
          }, 200);
        }
      }
    }
  }

  /**
   * 监听真实base64图片地址
   * @private
   * @returns void
   */
  @Watch("dataSource.posterUrl", { immediate: true, deep: true })
  private onChangePosterUrlData(value: any): void {
    if (value) {
      if (this.orgInfo.logo) {
        this.getUrl2base64(this.orgInfo.logo, "orgLogo");
      } else {
        setTimeout(() => {
          this.onSaveImg();
        }, 200);
      }
    }
  }

  // 提示语言
  private tipToast: boolean = false;

  /**
   * 监听提示语
   * @private
   * @returns void
   */
  @Watch("tipToast", { immediate: true, deep: true })
  private onChangeTipToast(value: any): void {
    if (value) {
      setTimeout(() => {
        this.tipToast = false;
      }, 2000);
    }
  }

  /**
   * 是否显示showLoading
   * @private
   * @returns boolean
   */
  private showLoading: boolean = false;

  /**
   * 是否显示分享指引
   * @private
   * @returns boolean
   */
  private showShareBg: boolean = false;

  /**
   * 是否显示海报
   * @private
   * @returns boolean
   */
  private showSharePopup: boolean = false;

  /**
   * 是否显示海报
   * @private
   * @returns boolean
   */
  private showPoster: boolean = false;

  // 生成海报
  protected createPoster() {
    this.showPoster = true;
  }

  // 关闭
  protected closePopup() {
    this.showPoster = false;
    this.$emit("hidePop");
  }

  // 分享微信
  protected async onShareFn(): Promise<void> {
    this.showShareBg = true;
  }

  /**
   * 获取当前登入机构信息
   * @private
   * @returns void
   */
  private get orgInfo(): any {
    return this.$store.getters.orgInfo || {};
  }

  // 微信分享内容变更
  protected async onWxShareFn(): Promise<void> {
    let currentUrl = location.href;
    let { content: result } = await WechatService.instance.getWechatJSSDK(currentUrl);
    let json = {
      title: this.dataSource.name || "", // 分享标题
      desc: "¥" + (this.dataSource.price || 0) + "\r\n推荐一个好物给你", // 分享描述
      link: currentUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      imgUrl: this.dataSource.bannerUrl, // 分享图标
      success: function (res) {
        console.log(res);
        // 设置成功
      },
    };
    await WechatService.instance.wechatShare(result, json); // 分享
  }

  //下载图片地址和图片名
  // protected downloadIamge(imgsrc:string, name:string) {
  //     const image = new Image();
  //     // 解决跨域 Canvas 污染问题
  //     image.setAttribute("crossOrigin", "anonymous");
  //     image.onload = function () {
  //         let canvas = document.createElement("canvas");
  //         canvas.width = image.width;
  //         canvas.height = image.height;
  //         const context = canvas.getContext("2d");
  //         context.drawImage(image, 0, 0, image.width, image.height);
  //         const url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
  //         const a = document.createElement("a"); // 生成一个a元素
  //         const event = new MouseEvent("click"); // 创建一个单击事件
  //         a.download = name || "photo"; // 设置图片名称
  //         a.href = url; // 将生成的URL设置为a.href属性
  //         a.dispatchEvent(event); // 触发a的单击事件
  //     };
  //     image.src = imgsrc;
  // }

  // 海报图片
  private posterSrc: string = "";

  // 保存图片
  protected onSaveImg() {
    const domObj = document.getElementById("posterHtml");
    html2canvas(domObj, {
      allowTaint: true,
      useCORS: false,
      onclone(doc) {
        let e = doc.querySelector("#posterHtml");
        (e as any).style.display = "block";
      },
    }).then((canvas) => {
      this.posterSrc = canvas.toDataURL("image/png");

      if (this.showLoading) {
        setTimeout(() => {
          this.tipToast = true;
        }, 100);
      }
      this.showLoading = false;
    });
  }

  // 企业logo
  protected orgLogo: string = "";

  // 海报图
  protected posterUrl: string = "";

  /**
   * 获取url获取base64
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async getUrl2base64(url: string, name: string): Promise<void> {
    try {
      let { content: result } = await UserService.instance.url2base64(url);
      if (result.data) {
        this[name] = "data:image/png;base64," + result.data;
        setTimeout(() => {
          this.onSaveImg();
        }, 200);
      }
    } catch (err) {}
  }
}
