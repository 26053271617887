
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Toast, Dialog } from "vant";
import { PackageService, FamilyService, UserService } from "src/services";
import EnumUtils from "src/utils/enum-utils";
import { RELATION } from "src/enums";

@Component
export default class EmployerList extends Vue {
  /**
   * 是否显示
   * @private
   * @returns string
   */
  @Prop({ type: Boolean, default: false })
  private value: boolean;

  /**
   * 获取企业信息
   * @private
   * @returns any
   */
  private get orgInfo(): any {
    let orgInfoStorge = localStorage.getItem("orgInfo");
    if (orgInfoStorge) {
      return JSON.parse(orgInfoStorge);
    }
    return {};
  }

  /**
   * 是否显示日期弹框
   * @private
   * @returns boolean
   */
  private get showPopup(): boolean {
    return this.value;
  }

  /**
   * 返回弹框状态
   * @private
   */
  private set showPopup(value: boolean) {
    this.$emit("input", value);
  }

  /**
   * 当前选中用户
   * @protected
   * @returns string
   */
  protected currentIndex: any = 0;

  /**
   * 家属列表
   * @protected
   * @returns Array<any>
   */
  protected employerList: Array<any> = [];

  /**
   * 获取当前登入信息
   * @private
   * @returns void
   */
  protected get userInfo(): any {
    return this.$store.getters.userInfo || {};
  }

  /**
   * 获取企业列表
   * @private
   * @returns void
   */
  private async getOrgList(): Promise<void> {
    try {
      let { content: res } = await UserService.instance.getOrgList();
      this.$emit("getOrgList", res.data);
      this.currentIndex = res.data.findIndex((item) => item.id == this.orgInfo.id);
      this.employerList = res.data || [];
    } catch (err) {
      Toast(err);
    }
  }

  /**
   * 获取企业列表
   * @private
   * @returns void
   */
  private async changeToken(item: any): Promise<void> {
    try {
      let { content: result } = await UserService.instance.changeToken(item.id);
      if (result.data) {
        this.$store.dispatch("setOrgInfo", item);
        this.$store.dispatch("setUserInfo", result.data);
        this.showPopup = false;
      }
    } catch (err) {
      Toast(err);
    }
  }

  /**
   * 当前选中用户
   * @private
   * @returns void
   */
  protected onSelectUser(index: any): void {
    this.currentIndex = index;
  }

  /**
   * 取消
   * @protected
   * @returns string
   */
  protected onCancel(): void {
    this.showPopup = false;
  }

  /**
   * 确认切换用户
   * @private
   * @returns void
   */
  protected onConfirm(): void {
    this.changeToken(this.employerList[this.currentIndex]);
  }

  /**
   * 关闭弹窗
   * @private
   * @returns void
   */
  protected closePop(): void {
    this.$emit("close");
  }

  /**
   * 组件创建钩子
   * @protected
   * @returns void
   */
  protected created() {
    this.getOrgList();
  }
}
