// import uContacts from "./contacts";
import uScheduling from "./scheduling";
import uSelectCity from "./select-city";
import pdfPreview from "./pdf-preview";
import uPageBack from "./page-back";
import uPageList from "./page-list";
import uFamilyList from "./family-list";
import uEmployerList from "./employer-list";
import uPackageComponent from "./package-component";
import uShare from "./share";

const components = {
  // uContacts
  uScheduling,
  uSelectCity,
  pdfPreview,
  uPageBack,
  uPageList,
  uFamilyList,
  uPackageComponent,
  uEmployerList,
  uShare,
};

// tslint:disable-next-line:variable-name
export function install(Vue: any, opts: any = {}) {
  Object.keys(components).forEach((key) => {
    Vue.component(key, components[key]);
  });
}

export default { ...components, install };
