const familyList = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/family/list.vue")), "family");
const operationFamily = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/family/operation.vue")), "family");

export default [
  {
    path: "/family/list",
    name: "familyList",
    component: familyList,
    meta: {
      title: "受检人管理",
    },
  },
  {
    path: "/family/operation",
    name: "family-operation",
    component: operationFamily,
    meta: {
      title: "受检人信息",
    },
  },
];
