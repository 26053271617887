import { formatDate, formatDateTime, formatDateHHMM } from "src/utils/extends";

export function dateFilter(value: string | Date) {
  return formatDate(value) || "-";
}

export function dateTimeFilter(value: string | Date) {
  return formatDateTime(value) || "-";
}

export function dateTimeHHMM(value: string | Date) {
  return formatDateHHMM(value) || "-";
}

export function filtersImgHost(value: string) {
  let index = value.indexOf(":");
  return value.substr(index + 1, value.length);
}
