import { apis } from "apis";
import HttpClient from "src/http/http-client";

/**
 * 业务服务基类。
 * @abstract
 * @class
 * @version 1.0.0
 */
export default abstract class ServiceBase {
  /**
   * 获取所有数据接口地址。
   * @protected
   * @property
   * @returns apis
   */
  protected get apis(): apis {
    return new apis();
  }

  /**
   * 获取一个全局 HTTP 客户端实例。
   * @protected
   * @property
   * @returns HttpClient
   */
  protected get http(): HttpClient {
    return HttpClient.instance;
  }
}
