const CryptoJS = require("crypto-js");
const key = "Shu&Kang";
const ivHex = "ShuKangCloud";

export default class DesSecretUtils {
  /**
   * Des CBC模式加密
   * @param word
   * @returns
   */
  public static decrypt(word: string): string {
    var keyHex = CryptoJS.enc.Utf8.parse(key);
    var _ivHex = CryptoJS.enc.Utf8.parse(ivHex);
    var encrypted = CryptoJS.DES.encrypt(word, keyHex, {
      iv: _ivHex,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.ciphertext.toString();
  }

  /**
   * Des CBC模式解密
   * @param word
   * @returns
   */
  public static encrypt(word: string): string {
    var keyHex = CryptoJS.enc.Utf8.parse(key);
    var _ivHex = CryptoJS.enc.Utf8.parse(ivHex);
    var decrypted = CryptoJS.DES.decrypt(
      {
        ciphertext: CryptoJS.enc.Hex.parse(word),
      },
      keyHex,
      {
        iv: _ivHex,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    return decrypted.toString(CryptoJS.enc.Utf8);
  }
}
