import { apis } from "apis";
import ServiceBase from "./service-base";
import IHttpResponse from "src/http/http-response";

/**
 * 企业通用设置表服务
 * @class
 * @version 1.0.0
 */
export default class EmployerGeneralSettingsService extends ServiceBase {
  /*
   * 企业通用设置表服务
   * @private
   * @return EmployerGeneralSettingsService
   */
  private static _instance: EmployerGeneralSettingsService;

  /**
   * 企业通用设置表服务
   * @static
   * @property
   * @returns EmployerGeneralSettingsService
   */
  public static get instance(): EmployerGeneralSettingsService {
    if (!this._instance) {
      this._instance = new EmployerGeneralSettingsService();
    }

    return this._instance;
  }

  /**
   * 获取企业参数配置列表
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async employerGeneralSettingsList(): Promise<IHttpResponse> {
    let response = await this.apis.employerGeneralSettingsList({});

    return response;
  }
}
