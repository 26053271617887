import Type from "src/utils/type";

export { DoctorTitle_TYPE };

/**
 * 职称
 * @enum
 * @version 1.0.0
 */
enum DoctorTitle_TYPE {
  /**
   * 医师
   * @member
   */
  RESIDENTSRESIDENTS = 1,

  /**
   * 主治医师
   * @member
   */
  ATTENDING,

  /**
   * 副主任医师
   * @member
   */
  DEPUTYCHIEFPHYSICIAN,

  /**
   * 主任医师
   * @member
   */
  CHIEFPHYSICIAN,
}

Type.setMetadata(DoctorTitle_TYPE, {
  RESIDENTSRESIDENTS: {
    description: "医师",
  },
  ATTENDING: {
    description: "主治医师",
  },
  DEPUTYCHIEFPHYSICIAN: {
    description: "副主任医师",
  },
  CHIEFPHYSICIAN: {
    description: "主任医师",
  },
});
