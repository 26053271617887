const aiAssistant = (resolve: any) =>
  (<any>require).ensure([], () => resolve(require("src/views/ai/assistant/views/home.vue")), "ai-assistant");
const aiPackage = (resolve: any) =>
  (<any>require).ensure([], () => resolve(require("src/views/ai/assistant/views/package/index.vue")), "ai-assistant");
const aiPackageOptional = (resolve: any) =>
  (<any>require).ensure([], () => resolve(require("src/views/ai/assistant/views/package/optional.vue")), "ai-assistant");
const aiReportList = (resolve: any) =>
  (<any>require).ensure([], () => resolve(require("src/views/ai/assistant/views/report/list.vue")), "ai-assistant");
const aiUpdatePhone = (resolve: any) =>
  (<any>require).ensure([], () => resolve(require("src/views/ai/assistant/views/user/update-phone.vue")), "ai-assistant");

export default [
  {
    path: "/ai",
    name: "ai",
    component: aiAssistant,
    meta: {
      title: "AI助手",
      keepAlive: true,
    },
  },
  {
    path: "/ai-assistant",
    name: "ai-assistant",
    component: aiAssistant,
    meta: {
      title: "AI助手",
      keepAlive: true,
    },
  },
  {
    path: "/ai-package",
    name: "ai-package",
    component: aiPackage,
    meta: {
      title: "套餐详情",
    },
  },
  {
    path: "/ai-package-optional",
    name: "ai-package-optional",
    component: aiPackageOptional,
    meta: {
      title: "体检自选加项",
    },
  },
  {
    path: "/ai-report-list",
    name: "ai-report-list",
    component: aiReportList,
    meta: {
      title: "报告列表",
    },
  },
  {
    path: "/ai-update-phone",
    name: "ai-update-phone",
    component: aiUpdatePhone,
    meta: {
      title: "完善手机号",
    },
  },
];
