const reportLogin = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/report/report-login.vue")), "report");
const identityVerify = (resolve: any) =>
  (<any>require).ensure([], () => resolve(require("src/views/report/identity-verify.vue")), "report");
const sportPdf = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/report/sport-pdf.vue")), "report");
const reportAppointmentList = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/report/list.vue")), "report");
const reportAppointmentUpload = (resolve: any) =>
  (<any>require).ensure([], () => resolve(require("src/views/report/upload.vue")), "report");
const reportAppointmentDetail = (resolve: any) =>
  (<any>require).ensure([], () => resolve(require("src/views/report/detail.vue")), "report");
const reportResult = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/report/physical-results.vue")), "report");
const reportQuery = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/report/query.vue")), "report");
const addReport = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/report/addReport.vue")), "report");
const reportDecode = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/report/decode.vue")), "report");
const reportReplacementReport = (resolve: any) =>
  (<any>require).ensure([], () => resolve(require("src/views/report/replacementReport.vue")), "report");

export default [
  {
    path: "/report/login",
    name: "reportLogin",
    component: reportLogin,
    meta: {
      tabbarName: "reportLogin",
      keepAlive: true,
      title: "本人验证",
    },
  },
  {
    path: "/sport/pdf",
    name: "sportPdf",
    component: sportPdf,
    meta: {
      title: "本人验证",
    },
  },
  {
    path: "/report/verify",
    name: "identityVerify",
    component: identityVerify,
    meta: {
      title: "身份验证",
    },
  },
  {
    path: "/report/appointment-list",
    name: "reportAppointmentList",
    component: reportAppointmentList,
    meta: {
      tabbarName: "reportLogin",
      keepAlive: true,
      title: "报告列表页",
    },
  },
  {
    path: "/report/appointment-upload",
    name: "reportAppointmentUpload",
    component: reportAppointmentUpload,
    meta: {
      title: "上传报告",
    },
  },
  {
    path: "/report/appointment-detail",
    name: "reportAppointmentDetail",
    component: reportAppointmentDetail,
    meta: {
      title: "报告详情",
      keepAlive: false,
    },
  },
  {
    path: "/report/query",
    name: "reportQuery",
    component: reportQuery,
    meta: {
      title: "查询报告",
    },
  },
  {
    path: "/report/add-report",
    name: "addReport",
    component: addReport,
    meta: {
      title: "体检报告添加",
    },
  },
  {
    path: "/report/appointment-result",
    name: "reportAppointmentResult",
    component: reportResult,
  },
  {
    path: "/report/decode",
    name: "reportDecode",
    component: reportDecode,
    meta: {
      title: "报告解读",
    },
  },
  {
    path: "/report/replacement",
    name: "reportReplacementReport",
    component: reportReplacementReport,
    meta: {
      title: "选择报告",
    },
  },
];
