import { apis } from "apis";
import ServiceBase from "./service-base";
import IHttpResponse from "src/http/http-response";
import HttpClient from "src/http/http-client";

/**
 * 组织机构服务。
 * @class
 * @version 1.0.0
 */
export default class OrganizationService extends ServiceBase {
  /*
   * 组织服务实例
   * @private
   * @return OrganizationService
   */
  private static _instance: OrganizationService;

  /**
   * 组织实例
   * @static
   * @property
   * @returns OrganizationService
   */
  public static get instance(): OrganizationService {
    if (!this._instance) {
      this._instance = new OrganizationService();
    }

    return this._instance;
  }

  /**
   * 获取机构列表
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async getHospitalList(data: any): Promise<IHttpResponse> {
    let response = await this.apis.getHospitalList({
      data: data,
    });

    return response;
  }

  /**
   * 获取最近距离的机构列表
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async getNearestHospitalList(data: any): Promise<IHttpResponse> {
    let response = await this.apis.getNearestHospitalList({
      data: data,
    });

    return response;
  }

  /**
   * 获取某个机构排期
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async getMedicalOrgDateInfo(filter: any): Promise<IHttpResponse> {
    let response = await this.apis.getMedicalOrgDateInfo({
      data: filter,
    });

    return response;
  }

  /**
   * 获取机构排期
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async getSupportHospitalSchedules(data: any): Promise<IHttpResponse> {
    let response = await this.apis.getSupportHospitalSchedules({
      data: data,
      loading: false,
    });

    return response;
  }
}
