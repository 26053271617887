const physicalPackage = (resolve: any) =>
  (<any>require).ensure([], () => resolve(require("src/views/oppo/package/physical-package-list.vue")), "oppo-package");

export default [
  {
    path: "/physical-package-oppo",
    name: "physical-package-oppo",
    component: physicalPackage,
    meta: {
      title: "体检套餐选择",
    },
  },
];
