const healthInsuranceDetail = (resolve: any) =>
  (<any>require).ensure([], () => resolve(require("src/views/healthProduct/health/detail.vue")), "healthInsurance");
const healthOrderSubmit = (resolve: any) =>
  (<any>require).ensure([], () => resolve(require("src/views/healthProduct/health/buy.vue")), "healthInsurance");
const healthTransfer = (resolve: any) =>
  (<any>require).ensure([], () => resolve(require("src/views/healthProduct/health/transfer.vue")), "healthInsurance");
const healthDetail = (resolve: any) =>
  (<any>require).ensure([], () => resolve(require("src/views/healthProduct/health/order-detail.vue")), "health");

export default [
  {
    path: "/health/order-detail",
    name: "healthDetail",
    component: healthDetail,
    meta: {
      title: "健康险订单详情",
    },
  },
  {
    path: "/healthInsurance/detail",
    name: "healthInsuranceDetail",
    component: healthInsuranceDetail,
    meta: {
      title: "健康险商品详情",
      pageName: "GJ_健康险商品详情页",
    },
  },
  {
    path: "/healthInsurance/buy",
    name: "healthInsurance-buy",
    component: healthOrderSubmit,
    meta: {
      title: "健康险商品确认订单",
      pageName: "GJ_健康险商品确认订单页",
    },
  },
  {
    path: "/healthInsurance/healthTransfer",
    name: "healthTransfer",
    component: healthTransfer,
    meta: {
      title: "健康险商品中转页",
      pageName: "GJ_健康险商品中转页",
    },
  },
];
