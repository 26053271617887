
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Toast } from "vant";
import { OrganizationService, MedicalOrderService } from "src/services";
import dayjs from "dayjs";

/**
 * 排期日历组件
 * @class
 * @version 1.0.0
 */
@Component
export default class Scheduling extends Vue {
  /**
   * 套餐id
   * @private
   * @returns string
   */
  @Prop({ type: [String, Number], default: "" })
  private packageId: string | number;

  /**
   * 套餐编号
   * @private
   * @returns string
   */
  @Prop({ type: [String, Number], default: "" })
  private packageCode: string | number;

  /**
   * 机构id
   * @private
   * @returns string
   */
  @Prop({ type: [String, Number], default: "" })
  private hospitalId: string | number;

  /**
   * 机构编号
   * @private
   * @returns string
   */
  @Prop({ type: [String, Number], default: "" })
  private hospitalCode: string | number;

  /**
   * 是否显示
   * @private
   * @returns string
   */
  @Prop({ type: Boolean, default: false })
  private value: boolean;

  /**
   * 是否获取医疗
   * @private
   * @returns string
   */
  @Prop({ type: Boolean, default: false })
  private isMedical: boolean;

  /**
   * 初始化默认选中日期
   * @private
   * @returns string
   */
  @Prop({ type: String, default: null })
  private defaultDate: any;

  /**
   * 回显时间段
   * @private
   * @returns string
   */
  @Prop({ type: String, default: () => "" })
  private defaultShifts: String;

  /**
   * 回显时间段
   * @private
   * @returns void
   */
  @Watch("defaultShifts", { immediate: true, deep: true })
  private onDefaultShifts(value: any): void {
    if (value) {
      this.selectShift = value;
    }
  }

  /**
   * 回显日期
   * @private
   * @returns void
   */
  private defaultTempDate: any = dayjs("1970/01/01").toDate();

  /**
   * 回显日期
   * @private
   * @returns void
   */
  @Watch("defaultDate", { immediate: true, deep: true })
  private onDefaultDate(value: any): void {
    if (value) {
      this.defaultTempDate = dayjs(value).toDate();
    }
  }

  /**
   * 排期数据
   * @private
   * @returns any
   */
  private schedulingData: any = [];

  /**
   * 时间段格式
   * @private
   * @returns any
   */
  private shiftsList: any = [];

  /**
   * 最小日期
   * @private
   * @returns any
   */
  // private defaultDate: any = dayjs("1970/01/01").toDate();

  /**
   * 最小日期
   * @private
   * @returns string
   */
  private minDate: any = "";

  /**
   * 最大日期
   * @private
   * @returns string
   */
  private maxDate: string = "";

  /**
   * 选中日期
   * @private
   * @returns string
   */
  private seletDate: string = "";

  /**
   * 选择的时间段
   * @private
   * @returns string
   */
  private selectShift: string = "";

  /**
   * 是否显示日期弹框
   * @private
   * @returns boolean
   */
  private get showDate(): boolean {
    return this.value;
  }

  /**
   * 返回弹框状态
   * @private
   */
  private set showDate(value: boolean) {
    this.$emit("input", value);
  }

  /**
   * 自定义排期格式
   * @private
   * @returns void
   */
  private formatter(day: any): void {
    let date = dayjs(day.date).format("YYYY/MM/DD");

    let selectDate = this.schedulingData.filter((item) => dayjs(item.startTime).isSame(dayjs(date)));
    if (selectDate && selectDate.length > 0 && selectDate[0].personCount > 0) {
      // day.bottomInfo = selectDate[0].personCount == 1 ? '可约' : `剩余${selectDate[0].personCount}`;
      day.bottomInfo = "可约";
    } else if (selectDate && selectDate.length > 0 && selectDate[0] && selectDate[0].surplusType === 2) {
      day.bottomInfo = "约满";
      day.type = "disabled";
    } else {
      day.type = "disabled";
    }

    return day;
  }

  /**
   * 日期确认选择
   * @private
   * @returns string
   */
  private onDateConfirm(date: any): void {
    if (dayjs("1970/01/01").isSame(dayjs(date))) {
      this.$emit("onDateConfirm", null, null);
    } else {
      if (this.shiftsList?.length) {
        if (!this.selectShift) {
          Toast("请先选择时间段");
          return;
        }
      }
      this.$emit("onDateConfirm", this.seletDate || this.defaultDate, this.selectShift);
    }
  }

  /**
   * 处理默认日期
   * @private
   * @returns void
   */
  private get formatDefaultDate(): any {
    return this.defaultDate ? dayjs(this.defaultDate).toDate() : null;
  }

  /**
   * 监听弹框变化
   * @private
   * @returns void
   */
  @Watch("showDate")
  private onShowDate(value: boolean): void {
    if (value) {
      this.shiftsList = [];
      this.selectShift = "";
      this.seletDate = dayjs(this.defaultDate).format("YYYY/MM/DD") != "1970/01/01" ? dayjs(this.defaultDate).format("YYYY/MM/DD") : "";
      if (this.isMedical) {
        this.getMedicalOrderSchedule();
      } else {
        this.getMedicalOrgDateInfo();
      }
    }
  }

  /**
   * 获取某个机构排期
   * @private
   * @returns void
   */
  private async getMedicalOrgDateInfo(): Promise<void> {
    try {
      let { content: result } = await OrganizationService.instance.getMedicalOrgDateInfo({
        packageId: this.packageId,
        hospitalId: this.hospitalId,
      });

      if (result.data) {
        this.schedulingData = result.data || [];

        this.$nextTick(() => {
          if (this.seletDate) {
            this.onSelectDate(this.seletDate);
            this.selectShift = this.defaultShifts ? this.defaultShifts + "" : "";
          }
        });
      }
    } catch (err) {
      Toast(err);
    }
  }

  /**
   * 获取某个机构排期
   * @private
   * @returns void
   */
  private async getMedicalOrderSchedule(): Promise<void> {
    try {
      let { content: result } = await MedicalOrderService.instance.getMedicalOrderSchedule(this.hospitalId);

      if (result.data) {
        this.schedulingData = result.data || [];

        this.$nextTick(() => {
          if (this.seletDate) {
            this.onSelectDate(this.seletDate);
            this.selectShift = this.defaultShifts ? this.defaultShifts + "" : "";
          }
        });
      }
    } catch (err) {
      Toast(err);
    }
  }

  /**
   * 选择日期
   * @private
   * @returns void
   */
  private async onSelectDate(date: any): Promise<void> {
    this.selectShift = "";
    let selectDate = this.schedulingData.filter((item) => dayjs(item.startTime).isSame(dayjs(date)));
    // this.seletDate = "2023/01/01";
    if (selectDate?.length) {
      this.seletDate = dayjs(date).format("YYYY/MM/DD");
      let isShiftsList = this.shiftsList?.length;
      if (selectDate[0].shifts?.length) {
        // 处理上面横着排版的日期结构 begin
        // if (!this.groupDates?.length)
        // {
        let schedulesObj = {};
        this.schedulingData.forEach((item) => {
          schedulesObj[dayjs(item.startTime).format("YYYY/MM/DD")] = item;
        });
        this.handlerCalendar(schedulesObj);
        // }
        // 处理上面横着排版的日期结构 end
        this.shiftsList = this.groupedData(selectDate[0].shifts, "dayType");
        if (!isShiftsList) {
          this.$nextTick(() => {
            let dom = document.getElementById("date-warp");
            let dom2 = document.getElementById("date-info");
            let offsetLeft = (dom2?.querySelector(".active") as any).offsetLeft;
            if (dom && dom2) {
              (dom as any).scrollLeft = offsetLeft - 30 > 0 ? offsetLeft - 30 : 0;
            }
          });
        }
      } else {
        this.defaultTempDate = dayjs(date).toDate();
        this.shiftsList = [];
      }
    }
  }

  /**
   * 分组
   * @private
   * @returns void
   */
  private groupedData(data: Array<any>, name: string): Array<any> {
    const newData = Object.values(
      data.reduce((acc, curr) => {
        if (!acc[curr[name]]) {
          acc[curr[name]] = { group: curr, items: [] };
        }
        acc[curr[name]].items.push(curr);
        return acc;
      }, {})
    );
    return newData;
  }

  /**
   * 选中时间段
   * @private
   * @returns void
   */
  private async onSelectShift(period: any): Promise<void> {
    this.selectShift = period;
  }

  /**
   * 排期数据
   * @private
   * @returns any
   */
  private weekList: any = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];

  /**
   * 显示日历空间日期
   * @private
   * @returns void
   */
  private handlerCalendar(schedulesObj: any): void {
    let dateList = [];
    // 获取下个月最后一天
    // let nextMonthDay = dayjs().add(1, "month").endOf('month');
    // 获取间隔天数
    // let intervalDay = dayjs(nextMonthDay).diff(dayjs(),'day');

    for (let i = 0; i < 60; i++) {
      let current = dayjs().add(i, "day").format("YYYY/MM/DD");

      let num = 0;
      let surplusType = 1;
      let currenObj = schedulesObj[current];
      if (currenObj) {
        num = currenObj.personCount;
        surplusType = currenObj.surplusType;
      }
      let obj = {
        date: current,
        num: num,
        surplusType: surplusType,
        txt: dayjs(current).format("MM.DD"),
        week: this.weekList[dayjs(current).day()],
      };
      dateList.push(obj);
    }
    this.groupDates = dateList;
  }

  /**
   * 横着排版日期结构数据
   * @private
   * @returns void
   */
  private groupDates: any = [];
}
