import { apis } from "apis";
import ServiceBase from "./service-base";
import IHttpResponse from "src/http/http-response";
import HttpClient from "src/http/http-client";

/**
 * 标签服务
 * @class
 * @version 1.0.0
 */
export default class hftagService extends ServiceBase {
  /*
   * 医疗服务实例
   * @private
   * @return MedicalOrserService
   */
  private static _instance: hftagService;

  /**
   * 医疗订单服务实例
   * @static
   * @property
   * @returns hftagService
   */
  public static get instance(): hftagService {
    if (!this._instance) {
      this._instance = new hftagService();
    }

    return this._instance;
  }

  /**
   * 获取标签集合
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async hftagList(data: any): Promise<IHttpResponse> {
    let response = await this.apis.hftagList({
      data: data,
    });

    return response;
  }

  /**
   * 获取标签详情
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async getHftagDetail(itemId: string | number): Promise<IHttpResponse> {
    let response = await this.apis.getHftagDetail({
      params: {
        itemId: itemId,
      },
    });

    return response;
  }
}
