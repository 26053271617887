import { apis } from "apis";
import ServiceBase from "./service-base";
import IHttpResponse from "src/http/http-response";
import HttpClient from "src/http/http-client";

/**
 * 组织机构服务。
 * @class
 * @version 1.0.0
 */
export default class PackageService extends ServiceBase {
  /*
   * 套餐服务实例
   * @private
   * @return PackageService
   */
  private static _instance: PackageService;

  /**
   * 套餐实例
   * @static
   * @property
   * @returns PackageService
   */
  public static get instance(): PackageService {
    if (!this._instance) {
      this._instance = new PackageService();
    }

    return this._instance;
  }

  /**
   * 获取体检套餐列表
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async getMedicalpackage(id: any): Promise<IHttpResponse> {
    let response = await this.apis.getMedicalpackage({
      params: { id },
    });

    return response;
  }

  /**
   * 获取套餐详情
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async getPackageInfo(filter: any): Promise<IHttpResponse> {
    let response = await this.apis.getPackageInfo({
      data: filter,
    });

    return response;
  }

  /**
   * 获取套餐加项
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async getOptionalPackageInfo(filter: any): Promise<IHttpResponse> {
    let response = await this.apis.getOptionalPackageInfo({
      data: filter,
    });

    return response;
  }

  /**
   * 获取套餐价格
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async getMedicalPackagePrice(filter: any, loading: boolean = true): Promise<IHttpResponse> {
    let response = await this.apis.getMedicalPackagePrice({
      data: filter,
      loading: loading,
    });

    return response;
  }

  /**
   * 获取人员套餐列表
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async getPackageList(id, questionnaireRId): Promise<IHttpResponse> {
    let response = await this.apis.getPackageList({
      params: { id, questionnaireRId },
    });

    return response;
  }

  /**
   * 获取套餐详情 - 不涉及机构
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async getPhysicalPackageOnlyInfo(data: any): Promise<IHttpResponse> {
    let response = await this.apis.getPhysicalPackageOnlyInfo({
      data,
    });

    return response;
  }

  /**
   * 是否展示一个套餐
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async singlePackage(id: any): Promise<IHttpResponse> {
    let response = await this.apis.singlePackage({
      params: { id },
    });

    return response;
  }

  /**
   * 套餐对比
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async packageComparing(checkeIds: any): Promise<IHttpResponse> {
    let response = await this.apis.packageComparing({ data: { checkeIds } });
    return response;
  }
}
