import { apis } from "apis";
import ServiceBase from "./service-base";
import IHttpResponse from "src/http/http-response";
import HttpClient from "src/http/http-client";

/**
 * 报告服务
 * @class
 * @version 1.0.0
 */
export default class ReportService extends ServiceBase {
  /*
   * 报告服务实例
   * @private
   * @return ReportService
   */
  private static _instance: ReportService;

  /**
   * 报告实例
   * @static
   * @property
   * @returns ReportService
   */
  public static get instance(): ReportService {
    if (!this._instance) {
      this._instance = new ReportService();
    }

    return this._instance;
  }

  /**
   * 获取报告列表
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async getPhysicalReportList(data: any): Promise<IHttpResponse> {
    let response = await this.apis.physicalReportList({
      data: data,
    });

    return response;
  }

  /**
   * 获取报告数量
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async getUnReadReportNum(): Promise<IHttpResponse> {
    let response = await this.apis.getUnReadReportNum({});

    return response;
  }

  /**
   * 获取报告详情
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async physicalReportDetail(id: any): Promise<IHttpResponse> {
    let response = await this.apis.physicalReportDetail({
      data: { id },
    });

    return response;
  }

  /**
   * 上传体检报告
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async uploadReport(data: any): Promise<IHttpResponse> {
    let response = await this.apis.uploadReport({
      data: data,
      serializeType: "form-data",
    });

    return response;
  }

  /**
   * 继续上传体检报告
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async proceedUploadReport(data: any): Promise<IHttpResponse> {
    let response = await this.apis.proceedUploadReport({
      data: data,
    });

    return response;
  }

  /**
   * 下载体检报告
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async downloadReport(id: any): Promise<IHttpResponse> {
    let response = await this.apis.downloadReport({ data: { id } });

    return response;
  }

  /**
   * 体检报告登入授权
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async reportLogin(data: any): Promise<IHttpResponse> {
    let response = await this.apis.reportLogin({
      data: data,
    });

    return response;
  }

  /**
   * 获取品牌列表
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async brandLogList(memberId: any): Promise<IHttpResponse> {
    let response = await this.apis.brandLogList({ data: { memberId } });

    return response;
  }

  /**
   * 添加品牌
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async addbrandLog(data: any): Promise<IHttpResponse> {
    let response = await this.apis.addbrandLog({
      data: data,
    });

    return response;
  }

  /**
   * 获取解析列表
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async queryAnalyze(reportId: any): Promise<IHttpResponse> {
    let response = await this.apis.queryAnalyze({
      params: { reportId },
    });

    return response;
  }

  /**
   * 取消预约
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async cancelReservation(id: any): Promise<IHttpResponse> {
    let response = await this.apis.cancelReservation({ params: { id } });

    return response;
  }

  /**
   * 获取ocr解析结果
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async getAnalysisResults(reportId: any, memberId: any): Promise<IHttpResponse> {
    let response = await this.apis.getAnalysisResults({
      params: { reportId, memberId },
    });

    return response;
  }

  /**
   * 初始化未解析的体检报告
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async initOCRAnalysis(memberId: any): Promise<IHttpResponse> {
    let response = await this.apis.initOCRAnalysis({
      params: { memberId },
    });

    return response;
  }

  /**
   * 获取问卷详情
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async getNewestQuestion(type = 1, employerMemberId, reportId): Promise<IHttpResponse> {
    let response = await this.apis.getNewestQuestion({
      params: { type, employerMemberId, reportId },
    });

    return response;
  }

  /**
   * 获取指定类型的最新问卷
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async getAnswering(data): Promise<IHttpResponse> {
    let response = await this.apis.getAnswering({ data });
    return response;
  }

  /**
   * 继续答卷
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async continueAnswering(type = 1): Promise<IHttpResponse> {
    let response = await this.apis.continueAnswering({
      params: { type },
    });

    return response;
  }

  /**
   * 问卷评估
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async questioEvaluating(data): Promise<IHttpResponse> {
    let response = await this.apis.questioEvaluating({
      data,
      loading: false,
    });
    return response;
  }

  /**
   * 判断是否有问卷记录
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async getRecordAnalyze(id): Promise<IHttpResponse> {
    let response = await this.apis.getRecordAnalyze({
      params: { id },
    });
    return response;
  }

  /**
   * 历史预测
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async getQuestionlist(type = 1): Promise<IHttpResponse> {
    let response = await this.apis.getQuestionlist({
      params: { type },
    });
    return response;
  }

  /**
   * 结束答卷
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async stopAnswering(type = 1): Promise<IHttpResponse> {
    let response = await this.apis.stopAnswering({
      params: { type },
    });
    return response;
  }

  /**
   * 校验客户端是否有有效问卷
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async checkQuestion(type = 1): Promise<IHttpResponse> {
    let response = await this.apis.checkQuestion({
      params: { type },
    });
    return response;
  }

  /**
   * 确认客户是否已经验证过是本人(验证 + 是否有报告，无报告也会返回true)
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async checkIdentity(): Promise<IHttpResponse> {
    let response = await this.apis.checkIdentity({});
    return response;
  }

  /**
   * 确认客户是否已经验证过是本人(只校验验证)
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async checkAuth(): Promise<IHttpResponse> {
    let response = await this.apis.checkAuth({});
    return response;
  }

  /**
   * 客户发送确认为本人验证码
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async sendConfirm(): Promise<IHttpResponse> {
    let response = await this.apis.sendConfirm({});
    return response;
  }

  /**
   * 客户确认为本人
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async confirmIdentity(data): Promise<IHttpResponse> {
    let response = await this.apis.confirmIdentity({ data });
    return response;
  }

  /**
   * 客户发送未授权报告验证码
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async sendQueryUnAuth(data): Promise<IHttpResponse> {
    let response = await this.apis.sendQueryUnAuth({ data });
    return response;
  }

  /**
   * 客户端查询未授权报告
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async queryUnAuth(data): Promise<IHttpResponse> {
    let response = await this.apis.queryUnAuth({ data });
    return response;
  }

  /**
   * 客户端授权报告
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async authReport(data): Promise<IHttpResponse> {
    let response = await this.apis.authReport({ data });
    return response;
  }

  /**
   * 获取品牌列表
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async getSupplierBrandList(): Promise<IHttpResponse> {
    let response = await this.apis.getSupplierBrandList({});
    return response;
  }

  /**
   * 预约解读
   * @public
   * @returns {Promise<IHttpResponse>}
   */
  async reservationExplain(data) {
    let response = await this.apis.reservationExplain({ data });
    return response;
  }

  /**
   * 获取预约解读详情
   * @public
   * @returns {Promise<IHttpResponse>}
   */
  async getReservationExplain(id) {
    let response = await this.apis.getReservationExplain({ params: { id } });
    return response;
  }

  /**
   * 根据时间段获取名额
   * @public
   * @returns {Promise<IHttpResponse>}
   */
  async getTimeSlotQuota(data) {
    let response = await this.apis.getTimeSlotQuota({ data });
    return response;
  }

  /**
   * 获取可约天数数据源
   * @public
   * @returns {Promise<IHttpResponse>}
   */
  async getTimeSlotData() {
    let response = await this.apis.getTimeSlotData({});
    return response;
  }
}
