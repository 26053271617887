import API from "./urls";
import IHttpResponse from "src/http/http-response";
import HttpClient from "src/http/http-client";
import IHttpRequest from "src/http/http-request";

/**
 * 发送ajax请求
 * @param {any} options 请求参数
 * @param {string} url 请求地址
 * @param {string} method 请求类型
 * @returns {any}
 */
const send = (url: string, options?: IHttpRequest, method: string = "post"): IHttpResponse => {
  // 如果未传入url以及参数抛出异常
  if (!url) {
    throw new Error("url is null.");
  }

  // 返回promise实例由用户处理
  return HttpClient.instance[method]({
    url,
    ...options,
  });
};

/**
 * 接口类
 * @version 1.0.0
 */
export default class Apis {
  // 以下为团检接口
  public jump2Personal = (option?: IHttpRequest) => send(API.jump2Personal, option);
  public queryNotSumbitAnalyzeReport = (option?: IHttpRequest) => send(API.queryNotSumbitAnalyzeReport, option);
  public login = (option?: IHttpRequest) => send(API.login, option);
  public uniteLogin = (option?: IHttpRequest) => send(API.uniteLogin, option);
  public smsLogin = (option?: IHttpRequest) => send(API.smsLogin, option);
  public SSOLogin = (option?: IHttpRequest) => send(API.SSOLogin, option);
  public oppoLogin = (option?: IHttpRequest) => send(API.oppoLogin, option);
  public vivoLogin = (option?: IHttpRequest) => send(API.vivoLogin, option);
  public uploadFile = (option?: IHttpRequest) => send(API.uploadFile, option);
  public delFile = (option?: IHttpRequest) => send(API.delFile, option);
  public getOrgInfo = (option?: IHttpRequest) => send(API.getOrgInfo, option, "get");
  public getOrgList = (option?: IHttpRequest) => send(API.getOrgList, option, "get");
  public changeToken = (option?: IHttpRequest) => send(API.changeToken, option, "get");
  public getMedicalpackage = (option?: IHttpRequest) => send(API.getMedicalpackage, option, "get");
  public getPackageInfo = (option?: IHttpRequest) => send(API.getPackageInfo, option);
  public getWechatJSSDK = (option?: IHttpRequest) => send(API.getWechatJSSDK, option, "get");
  public getHospitalList = (option?: IHttpRequest) => send(API.getHospitalList, option);
  public getNearestHospitalList = (option?: IHttpRequest) => send(API.getNearestHospitalList, option);
  public getOptionalPackageInfo = (option?: IHttpRequest) => send(API.getOptionalPackageInfo, option);
  public locationCity = (option?: IHttpRequest) => send(API.locationCity, option, "get");
  public getRegion = (option?: IHttpRequest) => send(API.getRegion, option, "get");
  public getMedicalOrgDateInfo = (option?: IHttpRequest) => send(API.getMedicalOrgDateInfo, option);
  public submitOrder = (option?: IHttpRequest) => send(API.submitOrder, option);
  public smsSend = (option?: IHttpRequest) => send(API.smsSend, option);
  public getFamilyList = (option?: IHttpRequest) => send(API.getFamilyList, option, "get");
  public getFamilyDetail = (option?: IHttpRequest) => send(API.getFamilyDetail, option, "get");
  public updateFamily = (option?: IHttpRequest) => send(API.updateFamily, option, "put");
  public addFamily = (option?: IHttpRequest) => send(API.addFamily, option);
  public wechatOauth = (option?: IHttpRequest) => send(API.wechatOauth, option);
  public forgetPassword = (option?: IHttpRequest) => send(API.forgetPassword, option);
  public updatePwd = (option?: IHttpRequest) => send(API.updatePwd, option);
  public getOrderList = (option?: IHttpRequest) => send(API.getOrderList, option);
  public getOrderQuantity = (option?: IHttpRequest) => send(API.getOrderQuantity, option);
  public getOrderDetail = (option?: IHttpRequest) => send(API.getOrderDetail, option, "get");
  public proceedPay = (option?: IHttpRequest) => send(API.proceedPay, option, "get");
  public cancelOrder = (option?: IHttpRequest) => send(API.cancelOrder, option, "put");
  public modifiedOrderDate = (option?: IHttpRequest) => send(API.modifiedOrderDate, option);
  public getPhysicalPackageOnlyInfo = (option?: IHttpRequest) => send(API.getPhysicalPackageOnlyInfo, option);
  public appointCheck = (option?: IHttpRequest) => send(API.appointCheck, option);

  // 意见反馈
  public getFeedbackList = (option?: IHttpRequest) => send(API.getFeedbackList, option);
  public addFeedback = (option?: IHttpRequest) => send(API.addFeedback, option);
  public updateFeedback = (option?: IHttpRequest) => send(API.updateFeedback, option);
  public getFeedbackDetail = (option?: IHttpRequest) => send(API.getFeedbackDetail, option, "get");
  public getUnReadMessage = (option?: IHttpRequest) => send(API.getUnReadMessage, option, "get");
  public uploadAvatar = (option?: IHttpRequest) => send(API.uploadAvatar, option);
  public modifyPhone = (option?: IHttpRequest) => send(API.modifyPhone, option);
  public modifyPhoneTwo = (option?: IHttpRequest) => send(API.modifyPhoneTwo, option);
  public getPreOrderInfo = (option?: IHttpRequest) => send(API.getPreOrderInfo, option);
  public deleteFamily = (option?: IHttpRequest) => send(API.deleteFamily, option, "delete");
  public checkDeleteFamily = (option?: IHttpRequest) => send(API.checkDeleteFamily, option, "get");

  // 套餐价格
  public getMedicalPackagePrice = (option?: IHttpRequest) => send(API.getMedicalPackagePrice, option);

  // 公告
  public getNoticeBannerList = (option?: IHttpRequest) => send(API.getNoticeBannerList, option, "get");
  public getNoticeMarqueeList = (option?: IHttpRequest) => send(API.getNoticeMarqueeList, option, "get");
  public getNoticePopUpList = (option?: IHttpRequest) => send(API.getNoticePopUpList, option, "get");
  public noticeRead = (option?: IHttpRequest) => send(API.noticeRead, option);
  public noticePageList = (option?: IHttpRequest) => send(API.noticePageList, option);
  public getNotice = (option?: IHttpRequest) => send(API.getNotice, option, "get");

  //
  public getUnReadReportNum = (option?: IHttpRequest) => send(API.getUnReadReportNum, option, "get");
  public physicalReportList = (option?: IHttpRequest) => send(API.physicalReportList, option);
  public physicalReportDetail = (option?: IHttpRequest) => send(API.physicalReportDetail, option, "get");
  public uploadReport = (option?: IHttpRequest) => send(API.uploadReport, option);
  public proceedUploadReport = (option?: IHttpRequest) => send(API.proceedUploadReport, option);
  public downloadReport = (option?: IHttpRequest) => send(API.downloadReport, option, "get");
  public reportLogin = (option?: IHttpRequest) => send(API.reportLogin, option);

  // 企业隐私条款管理
  public checkPrivacyTerm = (option?: IHttpRequest) => send(API.checkPrivacyTerm, option, "get");
  public getNewestPrivacyTerm = (option?: IHttpRequest) => send(API.getNewestPrivacyTerm, option, "get");
  public getPrivacyTerm = (option?: IHttpRequest) => send(API.getPrivacyTerm, option, "get");
  public agreePrivacyTerm = (option?: IHttpRequest) => send(API.agreePrivacyTerm, option, "get");
  // 收货地址
  public addAddress = (option?: IHttpRequest) => send(API.addAddress, option);
  public deleteAddress = (option?: IHttpRequest) => send(API.deleteAddress, option, "delete");
  public updateAddress = (option?: IHttpRequest) => send(API.updateAddress, option, "put");
  public setAddressDefault = (option?: IHttpRequest) => send(API.setAddressDefault, option, "put");
  public getAddressDetail = (option?: IHttpRequest) => send(API.getAddressDetail, option, "get");
  public getAddressPageList = (option?: IHttpRequest) => send(API.getAddressPageList, option);
  public getAddressList = (option?: IHttpRequest) => send(API.getAddressList, option, "get");
  // 实物订单
  public createGoodsOrder = (option?: IHttpRequest) => send(API.createGoodsOrder, option);
  public getPreGoodsOrderInfo = (option?: IHttpRequest) => send(API.getPreGoodsOrderInfo, option, "get");
  public getGoodsOrderDetail = (option?: IHttpRequest) => send(API.getGoodsOrderDetail, option, "get");
  public cancelGoodsOrder = (option?: IHttpRequest) => send(API.cancelGoodsOrder, option, "put");
  public confirmGoodsOrder = (option?: IHttpRequest) => send(API.confirmGoodsOrder, option);
  public proceedpayGoodsOrder = (option?: IHttpRequest) => send(API.proceedpayGoodsOrder, option, "get");
  public confirmGoodsOrderInfo = (option?: IHttpRequest) => send(API.confirmGoodsOrderInfo, option);
  public modifyGoodsOrderAddress = (option?: IHttpRequest) => send(API.modifyGoodsOrderAddress, option, "put");
  public onRevokeGoods = (option?: IHttpRequest) => send(API.onRevokeGoods, option, "put");
  // 健康产品
  public getHealthProductDetail = (option?: IHttpRequest) => send(API.getHealthProductDetail, option, "get");
  public getHealthProductList = (option?: IHttpRequest) => send(API.getHealthProductList, option);
  // 医疗服务
  public getPreMedicalOrderInfo = (option?: IHttpRequest) => send(API.getPreMedicalOrderInfo, option, "get");
  public createMedicalOrder = (option?: IHttpRequest) => send(API.createMedicalOrder, option);
  public getMedicalOrderDetail = (option?: IHttpRequest) => send(API.getMedicalOrderDetail, option, "get");
  public getMedicalOrderSupportHospitals = (option?: IHttpRequest) => send(API.getMedicalOrderSupportHospitals, option);
  public appointMedicalOrder = (option?: IHttpRequest) => send(API.appointMedicalOrder, option);
  public getMedicalOrderSchedule = (option?: IHttpRequest) => send(API.getMedicalOrderSchedule, option, "get");
  public changeMedicalOrderSchedule = (option?: IHttpRequest) => send(API.changeMedicalOrderSchedule, option, "put");
  public refundMedicalOrder = (option?: IHttpRequest) => send(API.refundMedicalOrder, option);
  public cancelMedicalOrder = (option?: IHttpRequest) => send(API.cancelMedicalOrder, option, "put");
  public cancelAppointMedicalOrder = (option?: IHttpRequest) => send(API.cancelAppointMedicalOrder, option, "put");
  public proceedPayMedicalOrder = (option?: IHttpRequest) => send(API.proceedPayMedicalOrder, option, "get");
  public getPayPreByNo = (option?: IHttpRequest) => send(API.getPayPreByNo, option, "get");
  public getPayPreByNoAnonymous = (option?: IHttpRequest) => send(API.getPayPreByNoAnonymous, option, "get");
  public toPay = (option?: IHttpRequest) => send(API.toPay, option);
  public submitOrderProductForm = (option?: IHttpRequest) => send(API.submitOrderProductForm, option);
  public getOrderProductForm = (option?: IHttpRequest) => send(API.getOrderProductForm, option, "get");
  public confirmOrder = (option?: IHttpRequest) => send(API.confirmOrder, option);

  // 健康短文
  public getHealthArticleList = (option?: IHttpRequest) => send(API.getHealthArticleList, option);
  public getHealthArticleDetail = (option?: IHttpRequest) => send(API.getHealthArticleDetail, option, "get");
  public getHealthArticlePageList = (option?: IHttpRequest) => send(API.getHealthArticlePageList, option);
  // 检查个人是否完善
  public checkIsPerfect = (option?: IHttpRequest) => send(API.checkIsPerfect, option, "get");

  // 获取配置 -- 业务员给企业设置
  public employerGeneralSettingsList = (option?: IHttpRequest) => send(API.employerGeneralSettingsList, option, "get"); // 获取规则(单独使用)

  // 体检预约验证
  public appointmentverification = (option?: IHttpRequest) => send(API.appointmentverification, option); // 体检预约验证

  // 自定义页面管理服务
  public getPageConfig = (option?: IHttpRequest) => send(API.getPageConfig, option, "get"); // 客户端获取企业页面配置
  public getPage = (option?: IHttpRequest) => send(API.getPage, option, "get"); // 体检预约验证

  //获取品牌列表
  public brandLogList = (option?: IHttpRequest) => send(API.brandLogList, option, "get"); // 获取品牌列表
  public addbrandLog = (option?: IHttpRequest) => send(API.addbrandLog, option); // 添加品牌

  // 获取解析列表
  public queryAnalyze = (option?: IHttpRequest) => send(API.queryAnalyze, option, "get"); // 获取解析列表
  public cancelReservation = (option?: IHttpRequest) => send(API.cancelReservation, option); // 取消预约

  public getAnalysisResults = (option?: IHttpRequest) => send(API.getAnalysisResults, option, "get"); // 获取ocr解析结果

  public initOCRAnalysis = (option?: IHttpRequest) => send(API.initOCRAnalysis, option, "get"); // 获取ocr解析结果

  public getMemberAsset = (option?: IHttpRequest) => send(API.getMemberAsset, option, "get"); // 获取人员折扣余额

  public getAllowAnonymou = (option?: IHttpRequest) => send(API.getAllowAnonymou, option, "get"); // 客户端获取订单详情

  public toPayAnonymous = (option?: IHttpRequest) => send(API.toPayAnonymous, option); // HR帮助预约订单统一支付
  public getEmployerAuth = (option?: IHttpRequest) => send(API.getEmployerAuth, option, "get");
  public addEmployerAuth = (option?: IHttpRequest) => send(API.addEmployerAuth, option);

  // 标签列表
  public hftagList = (option?: IHttpRequest) => send(API.hftagList, option); // 获取标签列表
  public getHftagDetail = (option?: IHttpRequest) => send(API.getHftagDetail, option, "get"); // 获取标签详情

  public getAddPackageItem = (option?: IHttpRequest) => send(API.getAddPackageItem, option, "get"); // 获取加项包项目
  public checkAddOnPackageRepeat = (option?: IHttpRequest) => send(API.checkAddOnPackageRepeat, option); // 加项包项目是否重复
  public getSupportHospitalSchedules = (option?: IHttpRequest) => send(API.getSupportHospitalSchedules, option); // 获取机构排期
  public checkFamilyPackage = (option?: IHttpRequest) => send(API.checkFamilyPackage, option, "get"); //  获取已配置的家人套餐数量

  /**
   * rights
   * 权益订单接口服务
   */
  public clientList = (option?: IHttpRequest) => send(API.clientList, option); // 客户端获取订单列表数据
  public getClient = (option?: IHttpRequest) => send(API.getClient, option, "get"); // 客户端获取订单详情
  public clientCancel = (option?: IHttpRequest) => send(API.clientCancel, option, "put"); // 客户端取消订单
  public clientActive = (option?: IHttpRequest) => send(API.clientActive, option, "put"); // 获取标签详情
  public clientGetCard = (option?: IHttpRequest) => send(API.clientGetCard, option, "get"); // 客户端获取卡片信息
  public clientGetCardByMemberRightId = (option?: IHttpRequest) => send(API.clientGetCardByMemberRightId, option, "get"); // 通过人员权益id获取卡片信息

  /**
   * rights
   * 权益服务
   */
  public getEmployerRights = (option?: IHttpRequest) => send(API.getEmployerRights, option, "get"); // 客户端权益详情查询

  // 注册
  public signUpClientAuth = (option?: IHttpRequest) => send(API.signUpClientAuth, option); // 企业员工注册

  // 获取健康险详情
  public getHealthInsDetail = (option?: IHttpRequest) => send(API.getHealthInsDetail, option, "get"); // 获取健康险详情

  // 问卷
  public getNewestQuestion = (option?: IHttpRequest) => send(API.getNewestQuestion, option, "get"); // 获取问卷详情
  public getAnswering = (option?: IHttpRequest) => send(API.getAnswering, option); // 获取指定类型的最新问卷
  public continueAnswering = (option?: IHttpRequest) => send(API.continueAnswering, option, "get"); // 继续答卷
  public stopAnswering = (option?: IHttpRequest) => send(API.stopAnswering, option, "get"); // 结束答卷
  public questioEvaluating = (option?: IHttpRequest) => send(API.questioEvaluating, option); // 问卷评估
  public getRecordAnalyze = (option?: IHttpRequest) => send(API.getRecordAnalyze, option, "get"); // 判断是否有问卷记录
  public getQuestionlist = (option?: IHttpRequest) => send(API.getQuestionlist, option, "get"); // 历史预测
  public checkQuestion = (option?: IHttpRequest) => send(API.checkQuestion, option, "get"); // 校验客户端是否有有效问卷

  // 套餐
  public getPackageList = (option?: IHttpRequest) => send(API.getPackageList, option, "get"); // 获取人员套餐列表
  public checkHasEffOrder = (option?: IHttpRequest) => send(API.checkHasEffOrder, option); // 是否有有效订单

  // 订单评价
  public getEvaluateData = (option?: IHttpRequest) => send(API.getOrderingEvaluate, option, "get"); // 客户端获取评价详情
  public evaluateBeforeAdd = (option?: IHttpRequest) => send(API.orderingEvaluateBefore, option); // 客户端提交订单评价前获取订单基本信息
  public evaluateAdd = (option?: IHttpRequest) => send(API.orderingEvaluateAdd, option); // 客户端提交订单评价

  // 体检报告服务
  public checkIdentity = (option?: IHttpRequest) => send(API.checkIdentity, option, "get"); // 确认客户是否已经验证过是本人(验证 + 是否有报告，无报告也会返回true)
  public checkAuth = (option?: IHttpRequest) => send(API.checkAuth, option, "get"); // 确认客户是否已经验证过是本人(只校验验证)
  public sendConfirm = (option?: IHttpRequest) => send(API.sendConfirm, option, "get"); // 客户发送确认为本人验证码
  public confirmIdentity = (option?: IHttpRequest) => send(API.confirmIdentity, option); // 客户发送确认为本人
  public sendQueryUnAuth = (option?: IHttpRequest) => send(API.sendQueryUnAuth, option); // 客户发送未授权报告验证码
  public queryUnAuth = (option?: IHttpRequest) => send(API.queryUnAuth, option); // 客户端查询未授权报告
  public authReport = (option?: IHttpRequest) => send(API.authReport, option); // 客户端授权报告
  public reservationExplain = (option?: IHttpRequest) => send(API.reservationExplain, option); // 预约解读
  public getReservationExplain = (option?: IHttpRequest) => send(API.getReservationExplain, option, "get"); // 获取预约解读详情
  public getTimeSlotQuota = (option?: IHttpRequest) => send(API.getTimeSlotQuota, option); // 根据时间段获取名额
  public getTimeSlotData = (option?: IHttpRequest) => send(API.getTimeSlotData, option, "get"); // 获取可

  // 报销申请
  public submitReimbursement = (option?: IHttpRequest) => send(API.submitReimbursement, option); // 提交报销申请
  public revokeReimbursement = (option?: IHttpRequest) => send(API.revokeReimbursement, option); // 撤销报销申请
  public queryReimbursement = (option?: IHttpRequest) => send(API.queryReimbursement, option); // 查询报销数据
  public getBankName = (option?: IHttpRequest) => send(API.getBankName, option, "get"); // 根据卡号获取银行名称
  public getBankList = (option?: IHttpRequest) => send(API.getBankList, option); // 获取银行列表
  public getApptNotice = (option?: IHttpRequest) => send(API.getApptNotice, option, "get"); // 获取预约须知
  public readApptNotice = (option?: IHttpRequest) => send(API.readApptNotice, option); // 读取预约须知
  public resetRead = (option?: IHttpRequest) => send(API.resetRead, option, "put"); // 重置预约须知
  public isUpdateMarriageStatus = (option?: IHttpRequest) => send(API.isUpdateMarriageStatus, option, "get"); // 是否可以修改婚姻状态
  public updateMarriageStatus = (option?: IHttpRequest) => send(API.updateMarriageStatus, option, "put"); // 修改婚姻状态
  public isShowPhysicalNotice = (option?: IHttpRequest) => send(API.isShowPhysicalNotice, option, "get"); // 是否展示检前须知
  public confirmPhysicalNotice = (option?: IHttpRequest) => send(API.confirmPhysicalNotice, option, "put"); // 确认检前须知

  // 浙商银行登录
  public bestdoLogin = (option?: IHttpRequest) => send(API.bestdoLogin, option); // 登录

  // 返回一个套餐
  public singlePackage = (option?: IHttpRequest) => send(API.singlePackage, option, "get"); // 是否展示套餐列表
  public packageComparing = (option?: IHttpRequest) => send(API.packageComparing, option); // 套餐对比

  // 钉钉授权登录
  public dingTalkLogin = (option?: IHttpRequest) => send(API.dingTalkLogin, option); // 钉钉授权登录

  // 获取品牌列表
  public getSupplierBrandList = (option?: IHttpRequest) => send(API.getSupplierBrandList, option); // 获取品牌列表

  public url2base64 = (option?: IHttpRequest) => send(API.url2base64, option, "get"); // 通过图片链接获取base64
}
