import { apis } from "apis";
import ServiceBase from "./service-base";
import IHttpResponse from "src/http/http-response";
import HttpClient from "src/http/http-client";
import { GaodeService, OrganizationService } from "src/services";

/**
 * 企业授权
 * @class
 * @version 1.0.0
 */
export default class EmployerAuth extends ServiceBase {
  /*
   * 企业授权
   * @private
   * @return EmployerAuth
   */
  private static _instance: EmployerAuth;

  /**
   * 企业授权服务实例
   * @static
   * @property
   * @returns EmployerAuth
   */
  public static get instance(): EmployerAuth {
    if (!this._instance) {
      this._instance = new EmployerAuth();
    }

    return this._instance;
  }

  /**
   * 获取授权
   * @public
   * @param {any} data
   * @returns {Promise<IHttpResponse>}
   */
  public async getEmployerAuth(): Promise<IHttpResponse> {
    // 编辑
    let response = await this.apis.getEmployerAuth({});

    return response;
  }

  /**
   * 新增授权记录
   * @public
   * @param {any} data
   * @returns {Promise<IHttpResponse>}
   */
  public async addEmployerAuth(data: any): Promise<IHttpResponse> {
    let response = await this.apis.addEmployerAuth({
      data: data,
    });

    return response;
  }
}
