import Type from "src/utils/type";

export { ServerItem_Type };

/**
 * 订单状态
 * @enum
 * @version 1.0.0
 */
enum ServerItem_Type {
  /**
   * 绿通项目
   * @member
   */
  GreenPass = 1,

  /**
   * 细胞存储
   * @member
   */
  CellStorage,

  /**
   * 心理咨询
   * @member
   */
  Counselling,
}

Type.setMetadata(ServerItem_Type, {
  GreenPass: {
    description: "绿通项目",
  },
  CellStorage: {
    description: "细胞存储",
  },
  Counselling: {
    description: "心理咨询",
  },
});
