import { apis } from "apis";
import ServiceBase from "./service-base";
import IHttpResponse from "src/http/http-response";
import HttpClient from "src/http/http-client";
import { GaodeService, OrganizationService } from "src/services";

/**
 * 加项包
 * @class
 * @version 1.0.0
 */
export default class AddPackageService extends ServiceBase {
  /*
   * 服务实例
   * @private
   * @return AddPackageService
   */
  private static _instance: AddPackageService;

  /**
   * 加项包服务实例
   * @static
   * @property
   * @returns AddPackageService
   */
  public static get instance(): AddPackageService {
    if (!this._instance) {
      this._instance = new AddPackageService();
    }

    return this._instance;
  }

  /**
   * 获取加项包项目
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async getAddPackageItem(id: any): Promise<IHttpResponse> {
    let response = await this.apis.getAddPackageItem({
      data: {
        id,
      },
    });

    return response;
  }

  /**
   * 加项包项目是否重复
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async checkAddOnPackageRepeat(data: any): Promise<IHttpResponse> {
    let response = await this.apis.checkAddOnPackageRepeat({
      data: {
        addOnPackageIds: data,
      },
    });

    return response;
  }
}
