import { Type } from "src/utils";

/**
 * 表示一个 Http请求响应状态码。
 * @enum
 * @version 1.0.0
 */
enum HttpResponseCode {
  /**
   * 成功。
   * @member
   */
  ok = 200,

  /**
   * 失败。
   * @member
   */
  failed = 201,

  /**
   * 数据重复。
   * @member
   */
  duplicated = 202,

  /**
   * 参数错误
   * @member
   */
  invalid = 300,

  /**
   * 无权限
   * @member
   */
  unauthorized = 401,

  /**
   * 内部错误
   * @member
   */
  error = 500,

  /**
   * 请求参数不完整或不正确。
   * @member
   */
  parameterError = 400,

  /**
   * 请求TOKEN失效
   * @member
   */
  tokenInvalid = 403,

  /**
   * HTTP请求类型不合法
   * @member
   */
  httpMehtodError = 405,

  /**
   * HTTP请求不合法,请求参数可能被篡改
   * @member
   */
  httpRequestError = 406,

  /**
   * 该URL已经失效
   * @member
   */
  URLExpireError = 407,
}

/*
 * 定义枚举元数据。
 */
Type.setMetadata(HttpResponseCode, {
  ok: {
    alias: "成功",
    description: "成功",
  },
  failed: {
    alias: "失败",
    description: "失败",
  },
  duplicated: {
    alias: "数据重复",
    description: "数据重复",
  },
  invalid: {
    alias: "参数错误",
    description: "参数错误",
  },
  unauthorized: {
    alias: "无权限",
    description: "无权限",
  },
  error: {
    alias: "内部错误",
    description: "内部错误",
  },
  parameterError: {
    alias: "请求参数不完整或不正确",
    description: "请求参数不完整或不正确",
  },
  tokenInvalid: {
    alias: "请求TOKEN失效",
    description: "请求TOKEN失效",
  },
  httpMehtodError: {
    alias: "HTTP请求类型不合法",
    description: "HTTP请求类型不合法",
  },
  httpRequestError: {
    alias: "HTTP请求不合法,请求参数可能被篡改",
    description: "HTTP请求不合法,请求参数可能被篡改",
  },
  URLExpireError: {
    alias: "该URL已经失效",
    description: "该URL已经失效",
  },
});

export default HttpResponseCode;
