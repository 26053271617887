import Type from "src/utils/type";

export { RELATION, ID_TYPE, MARRIAGE_STATUS, CHECK_STATUS, ID_TYPE_REGISTER, MARRIAGE_STATUS_REGISTER };

/**
 * 与本人关系
 * @enum
 * @version 1.0.0
 */
enum RELATION {
  /**
   * 父母
   * @member
   */
  parents = 1,

  /**
   * 配偶
   * @member
   */
  spouse,

  /**
   * 子女
   * @member
   */
  child,

  /**
   * 其他
   * @member
   */
  other,
}

Type.setMetadata(RELATION, {
  parents: {
    description: "父母",
  },
  spouse: {
    description: "配偶",
  },
  child: {
    description: "子女",
  },
  other: {
    description: "其他",
  },
});

/**
 * 证件类型
 * @enum
 * @version 1.0.0
 */
enum ID_TYPE {
  /**
   * 身份证
   * @member
   */
  IDCard = 1,

  /**
   * 军人证
   * @member
   */
  OfficerID,

  /**
   * 港澳通行证
   * @member
   */
  HongKongAndMacao,

  /**
   * 护照
   * @member
   */
  Passport,

  /**
   * 其他
   * @member
   */
  // Other = 99
}

Type.setMetadata(ID_TYPE, {
  IDCard: {
    description: "身份证",
  },
  OfficerID: {
    description: "军官证",
  },
  HongKongAndMacao: {
    description: "港澳通行证",
  },
  Passport: {
    description: "护照",
  },
  // Other:
  // {
  //     description: "其他"
  // }
});

/**
 * 证件类型 - 注册
 * @enum
 * @version 1.0.0
 */
enum ID_TYPE_REGISTER {
  /**
   * 身份证
   * @member
   */
  IDCard = 1,

  /**
   * 军人证
   * @member
   */
  OfficerID,

  /**
   * 港澳通行证
   * @member
   */
  HongKongAndMacao,

  /**
   * 护照
   * @member
   */
  Passport,

  /**
   * 台湾通行证
   * @member
   */
  TaiWan,

  /**
   * 户口簿
   * @member
   */
  ResidenceBooklet,

  /**
   * 出生证
   * @member
   */
  BirthCertificate,

  /**
   * 港澳居民居住证
   * @member
   */
  HongKongAndMacaoLiveCard,

  /**
   * 台湾居民居住证
   * @member
   */
  TaiWanLiveCard,

  /**
   * 外国人永久居留身份证
   * @member
   */
  Foreigners,

  /**
   * 其他
   * @member
   */
  Other = 99,
}

Type.setMetadata(ID_TYPE_REGISTER, {
  IDCard: {
    description: "身份证",
  },
  OfficerID: {
    description: "军官证",
  },
  HongKongAndMacao: {
    description: "港澳通行证",
  },
  Passport: {
    description: "护照",
  },
  TaiWan: {
    description: "台湾通行证",
  },
  ResidenceBooklet: {
    description: "户口簿",
  },
  BirthCertificate: {
    description: "出生证",
  },
  HongKongAndMacaoLiveCard: {
    description: "港澳居民居住证",
  },
  TaiWanLiveCard: {
    description: "台湾居民居住证",
  },
  Foreigners: {
    description: "外国人永久居留身份证",
  },
  Other: {
    description: "其他",
  },
});

/**
 * 婚姻状态
 * @enum
 * @version 1.0.0
 */
enum MARRIAGE_STATUS {
  /**
   * 已婚
   * @member
   */
  married = 1,

  /**
   * 未婚
   * @member
   */
  unmarried,
}

Type.setMetadata(MARRIAGE_STATUS, {
  married: {
    description: "已婚",
  },
  unmarried: {
    description: "未婚",
  },
});

/**
 * 婚姻状态
 * @enum
 * @version 1.0.0
 */
enum MARRIAGE_STATUS_REGISTER {
  /**
   * 已婚
   * @member
   */
  married = 0,

  /**
   * 未婚
   * @member
   */
  unmarried,
}

Type.setMetadata(MARRIAGE_STATUS_REGISTER, {
  married: {
    description: "已婚",
  },
  unmarried: {
    description: "未婚",
  },
});

/**
 * 下单结果
 * @enum
 * @version 1.0.0
 */
enum CHECK_STATUS {
  /**
   * 已下单
   * @member
   */
  ordered = 1,

  /**
   * 下单成功，不需要调整支付页面
   * @member
   */
  success = 2,

  /**
   * 下单成功，需要跳转微信支付页面
   * @member
   */
  successJump = 3,

  /**
   * 下单失败
   * @member
   */
  fail = 4,

  /**
   * 体检项目为空
   * @member
   */
  emptyProject = 5,

  /**
   * 客户信息不全
   * @member
   */
  userInfo = 6,

  /**
   * 机构信息、合同信息、套餐信息有误,
   * @member
   */
  org = 7,

  /**
   * 无排期或无号
   * @member
   */
  noDate = 8,

  /**
   * 体检日期已过
   * @member
   */
  checkedDate = 9,
}

Type.setMetadata(CHECK_STATUS, {
  ordered: {
    description: "已下单",
  },
  success: {
    description: "下单成功",
  },
  successJump: {
    description: "下单成功",
  },
  fail: {
    description: "下单失败",
  },
  emptyProject: {
    description: "体检项目为空",
  },
  userInfo: {
    description: "体检人信息不全",
  },
  org: {
    description: "机构信息、合同信息、套餐信息有误",
  },
  noDate: {
    description: "无排期或无号",
  },
  checkedDate: {
    description: "体检日期已过",
  },
});
