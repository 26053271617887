
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Toast } from "vant";

@Component
export default class PageList extends Vue {
  /**
   * 数据源
   * @protected
   * @returns Function
   */
  @Prop({ type: Function, required: true })
  protected data: Function;

  /**
   * 无数据图片
   * @protected
   * @returns String
   */
  @Prop({ type: String, default: "/images/notData/notData.png" })
  protected imgSrc: string;

  /**
   * 无数据标题
   * @protected
   * @returns String
   */
  @Prop({ type: String, default: "暂无数据" })
  protected noDataTitle: string;

  /**
   * 是否刷新
   * @private
   * @returns string
   */
  private refreshing: boolean = false;

  /**
   * 加载效果
   * @private
   * @returns string
   */
  private loading: boolean = false;

  /**
   * 是否到底
   * @private
   * @returns string
   */
  private finished: boolean = false;

  /**
   * 当前页
   * @private
   * @returns number
   */
  private pageIndex: any = 1;

  /**
   * 每页条数
   * @private
   * @returns number
   */
  private pageSize: any = 10;

  /**
   * 是否是最后一页
   * @private
   * @returns number
   */
  private isLastPage: boolean = false;

  /**
   * 总页数
   * @private
   * @returns number
   */
  private totalPages: any = 5;

  /**
   * 是否显示完成插槽
   * @param Boolean bool
   */
  protected get isShowFinishedSlot(): boolean {
    return !!this.$slots.finished;
  }

  /**
   * 表格重新加载方法
   * 如果参数为 true, 则强制刷新到第一页
   * @param Boolean bool
   */
  protected onRefresh(): void {
    this.pageIndex = 1;
    this.totalPages = 1;
    this.dataList = [];
    this.isLastPage = false;
    this.finished = false;
    this.loadData();
  }

  /**
   * 加载数据方法
   * @param {Object} pagination 分页选项器
   */
  protected async loadData(pagination?: any) {
    if (this.pageIndex > this.totalPages) return;
    this.loading = true;
    const parameter = Object.assign({
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
    });
    try {
      const result = await this.data(parameter);

      if (result) {
        this.pageIndex++;
        this.totalPages = result.totalPages;
        this.dataList = this.dataList.concat(result.items || []);
        if (this.pageIndex > result.totalPages) {
          this.finished = true;
        }
      } else {
        this.totalPages = 0;
        this.finished = true;
      }
    } catch (error) {
      Toast(error);
    } finally {
      this.loading = false;
      this.refreshing = false;
    }
  }

  /**
   * 内容显示区域高度
   * @private
   * @returns number
   */
  private viewHeight: any = 0;

  /**
   * 数据集合
   * @private
   * @returns number
   */
  private dataList: Array<any> = [];

  /**
   * 防止重复加载
   * @private
   * @returns number
   */
  private handling: boolean = false;
}
