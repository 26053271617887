import { OrderService } from "src/services";
import { Toast } from "vant";

/**
 * 本人预约
 * @private
 * @returns void
 */
const onMakeSelf = async (that: any) => {
  try {
    let { content: result } = await OrderService.instance.appointCheck();
    if (result?.data) {
      if (result?.data.isLimited) {
        that.$router.push({ name: "appointCheck" });
      } else {
        const sex = that.personalUser.sex;
        const marriageStatus = that.personalUser.marriageStatus;
        // 女性未婚 展示检前须知页
        if (that.isEditStatus && that.isShowNotice && sex == 0 && marriageStatus == 1) {
          that.$router.push({ name: "physical-inspection", query: { type: "1" } });
        } else {
          // 仅有一个套餐跳转机构列表
          if (that.packageId) {
            localStorage.setItem("selectUser", JSON.stringify(that.personalUser));
            that.$router.push({ name: "orgList", query: { packageId: that.packageId } });
          } else {
            // 多个套餐跳转套餐列表
            that.$router.push({ name: "physical-package", query: { type: "1" } });
          }
        }
      }
    }
  } catch (err) {
    Toast(err);
  }
};

/**
 * 公用方法-页面跳转
 */
export default function onPageTo(item: any, that: any) {
  if (!item.type) {
    return;
  }
  if (item.type == 101) {
    // 单个产品
    if (item.dataList && item.dataList.length > 0) {
      if (item.dataList[0].productType == 4) {
        that.$router.push({ name: "goodsDetail", query: { id: item.dataList[0].id } });
      } else {
        that.$router.push({ name: "medical", query: { id: item.dataList[0].id } });
      }
    }
  } else if (item.type == 102) {
    // 多个产品
    let ids = [];
    item.dataList.forEach((element) => {
      ids.push(element.id);
    });
    localStorage.setItem("productList", JSON.stringify(ids));
    that.$router.push({ name: "health-product-list" });
  } else if (item.type == 201) {
    // 单个文章
    if (item.dataList && item.dataList.length > 0) {
      that.$router.push({ name: "health-product-article-detail", query: { id: item.dataList[0].id } });
    }
  } else if (item.type == 202) {
    // 多个文章
    let ids = [];
    item.dataList.forEach((element) => {
      ids.push(element.id);
    });
    localStorage.setItem("articleList", JSON.stringify(ids));
    that.$router.push({ name: "health-product-article" });
  } else if (item.type == 3) {
    // 链接
    var reg = /(http|https):\/\/([\w.]+\/?)\S*/gi;
    if (item.link.match(reg)) {
      window.location.href = item.link;
    } else {
      window.location.href = location.protocol + "//" + item.link;
    }
  } else if (item.type == 401) {
    // 健康体检
    onMakeSelf(that);
  } else if (item.type == 402) {
    // 家属体检
    that.$router.push({ name: "physical-package", query: { type: "2" } });
  } else if (item.type == 403) {
    // 体检订单
    that.$router.push({ name: "order-list", query: { isPhysical: "1" } });
  } else if (item.type == 404) {
    // 体检报告
    that.$emit("msg", { type: 404 });
  } else if (item.type == 405) {
    // 健康档案
  } else if (item.type == 406) {
    // 报告解读(小程序)
  } else if (item.type == 407) {
    // 员工定制体检
    that.$router.push({ name: "aicustomized" });
  } else if (item.type == 408) {
    // 受检人管理
    that.$router.push({ name: "familyList" });
  } else if (item.type == 409) {
    // 报告解读(H5)
    that.$router.push({ name: "reportDecode" });
  } else if (item.type == 601) {
    // 隐私保护
    that.$router.push({ name: "login-privacy" });
  } else if (item.type == 602) {
    // 收货地址
    that.$router.push({ name: "address-list" });
  } else if (item.type == 603) {
    // 意见反馈
    that.$router.push({ name: "feedbackList" });
  } else if (item.type == 604) {
    // 账号设置
    that.$router.push({ name: "userSetting" });
  } else if (item.type == 605) {
    // 贴票报销
    that.$router.push({ name: "ticketingList" });
  }
}
