import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    orgInfo: null, // 机构信息
    userInfo: null, // 用户信息
    loadingCount: 0,
    historyCityList: [], // 选择历史城市集合
    priceIsDispaly: null,
    selectUser: null,
    selectOptionalItem: null,
    company: null,
    selectAddress: null,
    location: null,
    unReadMessageCount: 0,
    generalSettings: null, // 企业配置
    uploadParams: null, // 上传的forData参数
    queryReportParams: null, // 查询报告参数
    lastRouter: null, // 上传新报告是否成功
    currentLocaltion: "", // 选中城市
    isEditStatus: true, // 受检人管理-是否可编辑信息
    isShowNotice: false, // 是否显示体检须知
  },
  mutations: {
    // 修改机构信息
    SET_ORG_INFO(state: any, data: any) {
      state.orgInfo = data;
      if (data) {
        localStorage.setItem("orgInfo", JSON.stringify(data));
      } else {
        localStorage.removeItem("orgInfo");
      }
    },
    // 修改未读数量
    SET_UNREAD_COUNT(state: any, data: any) {
      state.unReadMessageCount = data;
    },
    SET_HISTORTY_CITY_LIST(state: any, data: Array<string>) {
      state.historyCityList = data;

      localStorage.setItem("historyCityList", JSON.stringify(data));
    },
    SET_SELECT_ADDRESS(state: any, data: any) {
      state.selectAddress = data;
      if (data) {
        localStorage.setItem("selectAddress", JSON.stringify(data));
      } else {
        localStorage.removeItem("selectAddress");
      }
    },
    SET_LOCATION(state: any, data: any) {
      state.location = data;
      if (data) {
        localStorage.setItem("location", JSON.stringify(data));
      } else {
        localStorage.removeItem("location");
      }
    },
    SET_CURRENT_LOCATION(state: any, data: any) {
      state.currentLocaltion = data;
      if (data) {
        localStorage.setItem("currentLocaltion", JSON.stringify(data));
      } else {
        localStorage.removeItem("currentLocaltion");
      }
    },
    // 用户信息
    SET_USER_INFO(state: any, data: any) {
      state.userInfo = data;
      localStorage.setItem("userInfo", JSON.stringify(data));
    },
    // 价格显示信息
    SET_PRICE_DISPLAY(state: any, data: any) {
      state.priceIsDispaly = data;
      localStorage.setItem("priceIsDispaly", JSON.stringify(data));
    },
    // 选中的用户
    SET_SELECT_USER(state: any, data: any) {
      state.selectUser = data;
      localStorage.setItem("selectUser", JSON.stringify(data));
    },
    // 选中自选项目
    SET_SELECT_OPTIONAL_ITEM(state: any, data: any) {
      state.selectOptionalItem = data;
      localStorage.setItem("selectOptionalItem", JSON.stringify(data));
    },
    // 修改公司信息
    SET_COMPANY(state: any, data: any) {
      state.company = data;
      localStorage.setItem("company", JSON.stringify(data));
    },
    // 修改数量
    SET_LOADING_COUNT(state: any, data: any) {
      state.loadingCount = data;
    },
    // 修改配置
    SET_GENERAL_SETTINGS(state: any, data: any) {
      state.generalSettings = data;
      if (data) {
        localStorage.setItem("generalSettings", JSON.stringify(data));
      } else {
        localStorage.removeItem("generalSettings");
      }
    },
    // 查询报告参数
    SET_REPORT_PARAMS(state: any, data: any) {
      state.queryReportParams = data;
      localStorage.setItem("queryReportParams", JSON.stringify(data));
    },
    // 上传的参数
    SET_UPLOAD_PARAMS(state: any, data: any) {
      state.uploadParams = data;
    },
    SET_LAST_ROUTER(state: any, data: string) {
      state.lastRouter = data;
      localStorage.setItem("lastRouter", data);
    },
    SET_IS_EDIT_STATUS(state: any, data: any) {
      state.isEditStatus = data;
    },
    SET_IS_SHOW_NOTICE(state: any, data: any) {
      state.isShowNotice = data;
    },
  },
  actions: {
    // 修改机构信息
    setOrgInfo(store: any, data: any) {
      store.commit("SET_ORG_INFO", data);
    },
    // 修改历史数据
    setHistoryCityList(store: any, data: string) {
      store.commit("SET_HISTORTY_CITY_LIST", data);
    },
    // 修改选中的地址
    setSelectAddress(store: any, data: string) {
      store.commit("SET_SELECT_ADDRESS", data);
    },
    // 修改当前位置信息
    setLocation(store: any, data: any) {
      store.commit("SET_LOCATION", data);
    },
    // 修改当前选择城市
    setCurrentLocation(store: any, data: any) {
      store.commit("SET_CURRENT_LOCATION", data);
    },
    setUserInfo(store: any, data: any) {
      store.commit("SET_USER_INFO", data);
    },
    // 价格显示信息
    setPriceIsDispaly(store: any, data: any) {
      store.commit("SET_PRICE_DISPLAY", data);
    },
    // 选中的用户
    setSelectUser(store: any, data: any) {
      store.commit("SET_SELECT_USER", data);
    },
    setSelectOptionalItem(store: any, data: any) {
      store.commit("SET_SELECT_OPTIONAL_ITEM", data);
    },
    setCompany(store: any, data: any) {
      store.commit("SET_COMPANY", data);
    },
    setLoadingCount(store: any, count: any) {
      store.commit("SET_LOADING_COUNT", count);
    },
    // 修改企业配置
    setGeneralSettings(store: any, data: any) {
      store.commit("SET_GENERAL_SETTINGS", data);
    },
    // 上传参数
    uploadParams(store: any, data: any) {
      store.commit("SET_UPLOAD_PARAMS", data);
    },
    // 报告查询参数
    setReportParams(store: any, data: any) {
      store.commit("SET_REPORT_PARAMS", data);
    },
    // 最后一个路由
    setLastRouter(store: any, data: string) {
      store.commit("SET_LAST_ROUTER", data);
    },
    // 受检人管理-是否可编辑信息
    setIsEditStatus(store: any, data: string) {
      store.commit("SET_IS_EDIT_STATUS", data);
    },
    // 是否显示体检须知
    setIsShowNotice(store: any, data: string) {
      store.commit("SET_IS_SHOW_NOTICE", data);
    },
  },
  getters: {
    // 获取机构信息
    orgInfo(state: any) {
      if (!state.orgInfo) {
        state.orgInfo = JSON.parse(localStorage.getItem("orgInfo"));
      }

      return state.orgInfo;
    },
    historyCityList(state: any) {
      if (state.historyCityList.length === 0) {
        state.historyCityList = JSON.parse(localStorage.getItem("historyCityList")) || [];
      }

      return state.historyCityList;
    },
    selectAddress(state: any) {
      if (!state.selectAddress) {
        state.selectAddress = JSON.parse(localStorage.getItem("selectAddress"));
      }

      return state.selectAddress;
    },
    location(state: any) {
      if (!state.location) {
        state.location = JSON.parse(localStorage.getItem("location")) || null;
      }

      return state.location;
    },
    currentLocaltion(state: any) {
      if (!state.currentLocaltion) {
        state.currentLocaltion = JSON.parse(localStorage.getItem("currentLocaltion")) || "";
      }

      return state.currentLocaltion;
    },
    userInfo(state: any) {
      if (!state.userInfo) {
        state.userInfo = JSON.parse(localStorage.getItem("userInfo"));
      }

      return state.userInfo;
    },
    priceIsDispaly(state: any) {
      if (!state.priceIsDispaly) {
        state.priceIsDispaly = JSON.parse(localStorage.getItem("priceIsDispaly"));
      }

      return state.priceIsDispaly;
    },
    selectUser(state: any) {
      if (!state.selectUser) {
        state.selectUser = JSON.parse(localStorage.getItem("selectUser"));
      }

      return state.selectUser;
    },
    selectOptionalItem(state: any) {
      if (!state.selectOptionalItem) {
        state.selectOptionalItem = JSON.parse(localStorage.getItem("selectOptionalItem"));
      }

      return state.selectOptionalItem;
    },
    company(state: any) {
      if (!state.company) {
        state.company = JSON.parse(localStorage.getItem("company"));
      }

      return state.company;
    },
    // 获取企业配置
    generalSettings(state: any) {
      if (!state.generalSettings) {
        state.generalSettings = JSON.parse(localStorage.getItem("generalSettings"));
      }

      return state.generalSettings;
    },
    // 获取报告查询参数
    getReportParams(state: any) {
      if (!state.queryReportParams) {
        state.queryReportParams = JSON.parse(localStorage.getItem("queryReportParams"));
      }

      return state.queryReportParams;
    },
    getLastRouter(state: any) {
      if (!state.lastRouter) {
        state.lastRouter = localStorage.getItem("lastRouter");
      }
      return state.lastRouter;
    },
    getIsEditStatus(state: any) {
      return state.isEditStatus;
    },
    getIsShowNotice(state: any) {
      return state.isShowNotice;
    },
  },
  modules: {},
});
